
import React from "react";
import "./ListaCategorias.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { FaTrashAlt } from "react-icons/fa";
import { CiEdit } from "react-icons/ci";
import { Button } from "@mui/material";
import { Redirect } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import SpinnerModal from "../../componentes/SpinnerModal/SpinnerModal";
import { GrFormSearch } from 'react-icons/gr';
import { eliminarCategoria, getCategorias } from "../../servicios/api";
import { Collapse } from "reactstrap";
import ModalMessage from "../../componentes/Modals/ModalMessages/ModalMessages";
import ModalCategorias from "../../componentes/Modals/ModalCategorias/ModalCategorias";
import ModalMoverCategorias from "../../componentes/Modals/ModalMoverCategorias/ModalMoverCategorias";

export default class ListaCategorias extends React.Component {


    constructor(props) {
        super(props);

        this.state = {

            loanding: false,
            activarEgr: false,
            activarIng: false,
            txt_buscador: "",
            array_filtro: [], 
            categoriasArray: [],
            icons: ["0.circle", "0.circle.fill", "0.square", "0.square.fill", "00.circle"],
            array: [],
            verMJS: false,
            modalTitulo: "",
            modalMessage: "",
            id_Categoria: "",
            selecCategoria: [],
            idEliminar: 0,
            array_subCat: [],
            ultimoCriterioFiltro: "name",
            ordenSubFiltro: 'asc',
            ultimoCriterio: "name",
            ordenSub: 'asc',
            sortBy: null,
            sortOrder: 'asc',

        }

    }
    async dataCategoria(array) {

        var categorias = {};
        array.forEach(item => {
            if (item.parent === "") {
                categorias[item.idcategoria] = {
                    ...item,
                    collapse: false,
                    subcategorias: []
                };
            }
        });

        array.forEach(item => {
            if (item.parent !== "" && categorias[item.parent]) {
                categorias[item.parent].subcategorias.push(item);
            }
        });

        var resultado = Object.values(categorias).sort((a, b) => a.name.localeCompare(b.name));
        resultado.forEach(categoria => {
            categoria.subcategorias.sort((a, b) => a.name.localeCompare(b.name));
        });

        this.setState({ categoriasArray: resultado })
        console.log(JSON.stringify(resultado));
        console.log(resultado.length)
        this.setState({ loanding: false })

    }

    async componentDidMount() {

        this.setState({ loanding: true })
        var resp = await getCategorias()
        var da = this.state.txt_buscador;
        this.setState({ txt_buscador: "" })

        if (resp.code == 0) {
            let ban = 0;
            let pos = 0;
            let arraytem = resp.data;
            this.dataCategoria(resp.data)


            arraytem.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });
            this.setState({ array: arraytem })

            console.log("//////////////////////")
            /*
                 
            
            
                        //console.log("buscando cat id " + this.state.id_Categoria)
                        for (let i = 0; i < arraytem.length; i++) {
                            arraytem[i].collapse = false
                            if (arraytem[i].idcategoria == this.state.id_Categoria) {
            
                                ban++;
                                pos = i;
            
                                //arraytem[i].collapse = false
            
                            }
                        }
            
            
            
            
                        await this.setState({ array: arraytem })
                        if (ban <= 0) {
                            //console.log("NO")
                            this.setState({ loanding: false })
                        }
                        else {
            
            
                            if (da == "" && this.state.activarEgr == false && this.state.activarIng == false) {
            
                                //console.log("SII")
                                setTimeout(async () => {
            
                                    //console.log("no es busqueda")
                                    this.abrirCollage(arraytem[pos], pos);
                                    //arraytem[pos].collapse = true
                                    this.setState({ loanding: false })
            
            
                                }, 1000);
            
                            }
                            else if (da == "" && this.state.activarEgr == true && this.state.activarIng == false) {
                                this.filtro(1);
                                //console.log("SII")
                                setTimeout(async () => {
            
                                    //console.log("no es busqueda")
                                    this.filtro(1);
                                    //arraytem[pos].collapse = true
                                    this.setState({ loanding: false })
            
            
            
                                }, 1000);
            
                            }
                            else if (da == "" && this.state.activarEgr == false && this.state.activarIng == true) {
                                this.filtro(2);
                                //console.log("SII")
                                setTimeout(async () => {
            
                                    //console.log("no es busqueda")
                                    this.filtro(2);
                                    //arraytem[pos].collapse = true
                                    this.setState({ loanding: false })
            
            
                                }, 1000);
            
                            }
                            else {
            
                                //console.log("Noo")
                                setTimeout(async () => {
            
                                    this.setState({ txt_buscador: da })
                                    //console.log("es busqueda")
                                    this.individual(da)
                                    //this.abrirCollage(arraytem[pos], pos);
                                    //arraytem[pos].collapse = true
                                    this.setState({ loanding: false })
            
            
            
            
                                }, 1000);
            
                            }
            
            
            
            
            
            
                        }
            */




        }
        else {
            this.setState({ loanding: false })
        }

    }



    filtro = async (bandera) => {

        this.setState({ txt_buscador: "" })
        if (bandera == 1) {

            if (this.state.activarEgr == false) {
                this.setState({ activarEgr: true })
                this.setState({ activarIng: false })
                this.filtrarlista(1)
            }
            else {
                this.setState({ activarEgr: false })
                this.setState({ activarIng: false })
                this.filtrarlista(3)
            }

        }
        else {
            if (this.state.activarIng == false) {
                this.setState({ activarEgr: false })
                this.setState({ activarIng: true })
                this.filtrarlista(2)
            }
            else {
                this.setState({ activarIng: false })
                this.setState({ activarEgr: false })
                this.filtrarlista(3)
            }
        }

    }

    save_input_locacion = async (e) => {

        this.setState({ activarIng: false, activarEgr: false })

        var cadenain = e.target.value.trim()
        this.setState({ txt_buscador: cadenain });
        var filtropuesto = this.state.array;
        var filtrofinal = [];

        if (cadenain.toLocaleLowerCase().length >= 1) {
            filtrofinal = filtropuesto.filter((item) => item.name.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase())
                || item.description.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase())
            )

            if (filtrofinal.length <= 0) {
                this.setState({ array_filtro: [] })
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                this.setState({ array_filtro: filtrofinal })
            }
        }

    }


    individual = async (e) => {

        var cadenain = e.trim()
        this.setState({ txt_buscador: cadenain });
        var filtropuesto = this.state.array;
        var filtrofinal = [];

        this.setState({ activarIng: false })
        this.setState({ activarEgr: false })

        if (cadenain.toLocaleLowerCase().length >= 1) {
            filtrofinal = filtropuesto.filter((item) => item.name.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase())
                || item.description.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase())
            )

            if (filtrofinal.length <= 0) {
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                this.setState({ array_filtro: filtrofinal })
            }
        }


    }


    f2iltrarlista = async (ban) => {

        var cadenain = ban == 1 ? "expense" : ban == 2 ? "income" : ""
        // this.setState({ txt_buscador: cadenain });
        var filtropuesto = this.state.array;
        var filtrofinal = [];

        if (cadenain.toLocaleLowerCase().length >= 1) {
            filtrofinal = filtropuesto.filter((item) => item.name.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase())
                || item.description.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase())
                || item.type.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase()),
            )

            if (filtrofinal.length <= 0) {
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                this.setState({ array_filtro: filtrofinal })
            }
        }


    }

    filtrarlista = (ban) => {

        var tipo = ban == 1 ? "expense" : ban == 2 ? "income" : ""
        const categorias = JSON.parse(JSON.stringify(this.state.categoriasArray));
        let filtrofinal = [];
        let i = 0;

        categorias.forEach((categoria) => {

            let nuevaCategoria = { ...categoria, subcategorias: [] };
            nuevaCategoria.subcategorias = categoria.subcategorias.filter(sub => sub.type === tipo);

            if (nuevaCategoria.subcategorias.length > 0 || categoria.type === tipo) {
                nuevaCategoria.collapse = i === 0 ? true : false;
                filtrofinal.push(nuevaCategoria);
                i++;
            }
        });
        this.setState({ array_filtro: filtrofinal });
    }


    buscarCategoriaPadre = (parentId) => {
        const parentCategory = this.state.array.find(cat => cat.idcategoria === parentId);
        return parentCategory ? parentCategory.name : '';
    };


    seleccionarTabla = (idcategoria, bandera) => {
        console.log(bandera + "  " + idcategoria)
        if (bandera === "lista") {
            const { array } = this.state;
            const newData = array.map((item) => {
                if (item.idcategoria == idcategoria) {
                    return { ...item, seleccionar: true };
                } else {
                    return { ...item, seleccionar: false };
                }
            });
            this.setState({ array: newData });
        }

        else if (bandera === "subcat") {
            const { array_subCat } = this.state;
            const newData = array_subCat.map((item) => {
                if (item.idcategoria == idcategoria) {
                    return { ...item, seleccionar: true };
                } else {
                    return { ...item, seleccionar: false };
                }
            });
            this.setState({ array_subCat: newData });
        }
        else { // filtro
            const { array_filtro } = this.state;
            const newData = array_filtro.map((item) => {
                if (item.idcategoria == idcategoria) {
                    return { ...item, seleccionar: true };
                } else {
                    return { ...item, seleccionar: false };
                }
            });
            this.setState({ array_filtro: newData });

        }
    }

    seleccionarTablaDatos = (idcategoria, indexG, bandera) => {

        console.log("Bandera: ", bandera);
        if (bandera === "subcat") {

            const categorias = JSON.parse(JSON.stringify(this.state.categoriasArray));
            var arrayT = categorias[indexG].subcategorias;
            console.log("Subcategorías antes del cambio:", JSON.stringify(arrayT));

            arrayT = arrayT.map((item) => {
                return {
                    ...item,
                    seleccionar: item.idcategoria === idcategoria, // Cambiar según la coincidencia
                };
            });

            console.log("Subcategorías después del cambio:", JSON.stringify(arrayT));
            categorias[indexG].subcategorias = arrayT;


            this.setState({ categoriasArray: categorias });
        }
        else {

            const categorias = JSON.parse(JSON.stringify(this.state.array_filtro));
            var arrayT = categorias[indexG].subcategorias;

            console.log("Subcategorías antes del cambio:", JSON.stringify(arrayT));

            // Mapear el array de subcategorías para actualizar 'seleccionar'
            arrayT = arrayT.map((item) => {
                return {
                    ...item,
                    seleccionar: item.idcategoria === idcategoria, // Cambiar según la coincidencia
                };
            });

            console.log("Subcategorías después del cambio:", JSON.stringify(arrayT));

            // Asignar el array modificado a las categorías
            categorias[indexG].subcategorias = arrayT;

            // Actualizar el estado con las categorías modificadas
            this.setState({ array_filtro: categorias });

        }
    }

    abrirCollageFiltro = async (array, pos) => {

        let categoriasT = JSON.parse(JSON.stringify(this.state.array_filtro));
        var posicion = categoriasT.findIndex(categoria => categoria.idcategoria === array.idcategoria);

        categoriasT[posicion].collapse = !categoriasT[posicion].collapse;
        /*
        for (let i = 0; i < categoriasT.length; i++) {
            if (i === pos) {
                categoriasT[i].collapse = true; //!categoriasT[i].collapse;
            } else {
                categoriasT[i].collapse = false;
            }
        }
        */

        this.setState({ array_filtro: categoriasT });
    };

    abrirCollage = async (array, pos) => {

        let categoriasT = JSON.parse(JSON.stringify(this.state.categoriasArray));
        var posicion = categoriasT.findIndex(categoria => categoria.idcategoria === array.idcategoria);

        categoriasT[posicion].collapse = !categoriasT[posicion].collapse;
        /*
        for (let i = 0; i < categoriasT.length; i++) {
            if (pos == i) {
                categoriasT[i].collapse = !categoriasT[i].collapse;
            }
            else {
                categoriasT[i].collapse = false;
            }
        }*/
        this.setState({ categoriasArray: categoriasT });

    }

    A2abrirCollage = async (array, pos) => {


        var filtropuesto = this.state.array;
        var resp = filtropuesto[pos].collapse;
        for (let i = 0; i < filtropuesto.length; i++) {
            if (pos == i) {
                if (resp == true) {
                    filtropuesto[i].collapse = false
                    this.setState({ array_subCat: [] })
                }
                else {

                    const datosFiltrados = filtropuesto.filter(item => item.parent === array.idcategoria);
                    datosFiltrados.sort((a, b) => {
                        const nameA = a.name.toLowerCase();
                        const nameB = b.name.toLowerCase();
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    });
                    this.setState({ array_subCat: datosFiltrados });
                    filtropuesto[i].collapse = true
                }
            }
            else {
                filtropuesto[i].collapse = false
            }
        }
        this.setState({ array: filtropuesto })

    }

    borrarArray = (array, pos) => {


        let a = ""
        let b = ""

        if (array.parent == "") {
            b = "Eliminar Categoría"
            a = array.name + " y todas las subcategorias";
            this.setState({ id_Categoria: array.idcategoria })
            this.seleccionarTabla(array.idcategoria, "lista")
        }
        else {
            b = "Eliminar Sub Categoría"
            this.setState({ id_Categoria: array.parent })
            this.seleccionarTabla(array.idcategoria, "subcat")
            a = array.name
        }


        //console.log(JSON.stringify(array))
        //console.log("POs 1 " + pos + " = " + array.id)
        this.setState({ idEliminar: array.id })
        this.setState({ modalTitulo: b })
        this.setState({ modalMessage: a })
        this.setState({ verMJS: true })

    }

    cerrarmodal = async (dato, bandera) => {

        if (dato == 1) {
            this.setState({ loanding: true })
            //console.log("POs " + this.state.idEliminar)
            //console.log("IdCat " + this.state.id_Categoria)
             // si envio id padre la api elimina todos si mando una sub categoria solo elimina 1
            var resp = await eliminarCategoria(this.state.idEliminar)

            this.setState({ verMJS: false })
            //this.setState({ loanding: false })
            this.componentDidMount()

            //var array = this.state.array_subCat
            //array.splice(this.state.idEliminar, 1)


            //this.setState({ array_subCat: array })

        }
        else {
            this.setState({ verMJS: !this.state.verMJS })
        }

    }

    movercategorias = (dato, item) => {

        if (dato == 1) {
            this.setState({ moverCat: !this.state.moverCat })
            this.setState({ id_Categoria: item })
            this.componentDidMount()
        }
        else if (dato == 2) {
            //this.setState({ id_Categoria: item.idcategoria })
            this.setState({ selecCategoria: item })
            this.setState({ moverCat: !this.state.moverCat })
        }
        else {
            this.setState({ moverCat: !this.state.moverCat })
        }
    }



    cerrarmodalCat = (bandera, array) => {



        //console.log("TEST \n" + JSON.stringify(array))

        if (bandera == -1) {
            this.setState({ verCat: !this.state.verCat })
        }

        else if (bandera == 1) {
            //console.log("OOO " + array.idcategoria)
            let b = ""
            if (array.parent == "") {
                b = "Categoría";
                this.setState({ modalMessage: "editarCat" })
                this.setState({ id_Categoria: array.idcategoria })
                this.seleccionarTabla(array.idcategoria, "lista")
            }
            else {
                b = "Sub Categoría"
                this.setState({ modalMessage: "editarSub" })
                this.setState({ id_Categoria: array.parent })
                this.seleccionarTabla(array.idcategoria, "subcat")
            }


            this.setState({ modalTitulo: "Modificar " + b })
            this.setState({ selecCategoria: array })


            this.setState({ verCat: !this.state.verCat })
        }
        else if (bandera == 2) {
            //console.log("LLego Aqui")
            this.setState({ verCat: !this.state.verCat })
            this.componentDidMount()
        }
        else {
            //console.log("OOO11 " + array.parent)

            this.setState({ id_Categoria: array.idcategoria })
            this.setState({ modalTitulo: "Nueva Sub Categoría" })
            this.setState({ modalMessage: "NuevaSub" })
            this.setState({ selecCategoria: array })
            this.setState({ verCat: !this.state.verCat })
        }

    }


    newCategoria = (dato) => {
        this.setState({ modalTitulo: "Nueva Categoría" })
        this.setState({ modalMessage: "NuevaCat" })
        this.setState({ selecCategoria: [] })
        this.setState({ verCat: !this.state.verCat })

    }


    ordenarSubcategorias = (array, criterio) => {

        let categoriasT = JSON.parse(JSON.stringify(this.state.categoriasArray));
        var indexCategoria = categoriasT.findIndex(categoria => categoria.idcategoria === array.idcategoria);


        var categoriaSeleccionada = categoriasT[indexCategoria];
        if (categoriaSeleccionada && categoriaSeleccionada.subcategorias) {
            // Verificar el estado actual del orden para este criterio
            const nuevoOrden = this.state.ultimoCriterio === criterio && this.state.ordenSub === "asc" ? "desc" : "asc";

            categoriaSeleccionada.subcategorias.sort((a, b) => {
                let valueA = a[criterio]?.toLowerCase() || "";
                let valueB = b[criterio]?.toLowerCase() || "";

                if (nuevoOrden === "asc") {
                    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
                } else {
                    return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
                }
            });

            // Actualizar el estado
            categoriasT[indexCategoria] = categoriaSeleccionada;
            this.setState({
                categoriasArray: categoriasT,
                ultimoCriterio: criterio,
                ordenSub: nuevoOrden,
            });
        } else {
            console.error("No se encontró la categoría o no tiene subcategorías");
        }
    };



    ordenarSubcategoriasFiltro = (array, criterio) => {

        let categoriasT = JSON.parse(JSON.stringify(this.state.array_filtro));
        var indexCategoria = categoriasT.findIndex(categoria => categoria.idcategoria === array.idcategoria);


        var categoriaSeleccionada = categoriasT[indexCategoria];
        if (categoriaSeleccionada && categoriaSeleccionada.subcategorias) {
            // Verificar el estado actual del orden para este criterio
            const nuevoOrden = this.state.ultimoCriterioFiltro === criterio && this.state.ordenSubFiltro === "asc" ? "desc" : "asc";

            categoriaSeleccionada.subcategorias.sort((a, b) => {
                let valueA = a[criterio]?.toLowerCase() || "";
                let valueB = b[criterio]?.toLowerCase() || "";

                if (nuevoOrden === "asc") {
                    return valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
                } else {
                    return valueA < valueB ? 1 : valueA > valueB ? -1 : 0;
                }
            });

            // Actualizar el estado
            categoriasT[indexCategoria] = categoriaSeleccionada;
            this.setState({
                array_filtro: categoriasT,
                ultimoCriterioFiltro: criterio,
                ordenSubFiltro: nuevoOrden,
            });
        } else {
            console.error("No se encontró la categoría o no tiene subcategorías");
        }
    };

    ordenarLista = (key) => {
        if (this.state.sortBy === key) {
            // Si ya está ordenado por la misma clave, cambiar el orden
            this.setState((prevState) => ({
                sortOrder: prevState.sortOrder === 'asc' ? 'desc' : 'asc',
            }));
        } else {
            // Si es una nueva clave de ordenamiento, ordenar ascendentemente
            this.setState({
                sortBy: key,
                sortOrder: 'asc',
            });
        }
    }

    render() {

        const { categoriasArray, sortBy, sortOrder } = this.state;

        // Función para ordenar los datos según la clave y el orden especificados
        const sortedData = [...categoriasArray].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[sortBy] > b[sortBy] ? 1 : -1;
            } else {
                return a[sortBy] < b[sortBy] ? 1 : -1;
            }
        });

        return (

            <>

                <ToastContainer />
                {(this.state.avanzar == true) ? (
                    <Redirect from='/panel' to={"/perfil"} />
                ) : null}

                {(this.state.loanding == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loanding} />
                ) : null}

                {(this.state.verMJS == true) ? (
                    <ModalMessage show={this.state.verMJS} titulo={this.state.modalTitulo}
                        mensaje={this.state.modalMessage} cerrarBorrar={this.cerrarmodal} />
                ) : null}

                {(this.state.verCat == true) ? (
                    <ModalCategorias show={this.state.verCat} titulo={this.state.modalTitulo} arrayInfo={this.state.selecCategoria}
                        bandera={this.state.modalMessage} arrayCategorias={this.state.array} cerrarmodal={this.cerrarmodalCat} />
                ) : null}

                {(this.state.moverCat == true) ? (
                    <ModalMoverCategorias show={this.state.moverCat} arrayInfo={this.state.selecCategoria}
                        arrayCategorias={this.state.array} cerrarmodal={this.movercategorias} />
                ) : null}


                <div className="contenedorhome  ">

                    <div className="row scroll">
                        <div className="col">

                            <br />
                            <label className="lbllistat letra">Categorías</label>
                            <br />

                            <br />
                            <div className="row align-items-end">
                                <div className="col-auto ">
                                    <div className="input-group ">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1"> <GrFormSearch /></span>
                                        </div>
                                        <input type="search" onChange={this.save_input_locacion} value={this.state.txt_buscador}
                                            className="form-control" aria-label="Username" placeholder="Buscar" aria-describedby="basic-addon1"></input>
                                    </div>
                                </div>
                                <div className="col"></div>

                                <div className="col-auto">
                                    <Button className={this.state.activarEgr == true ? "buttonCats" : "buttonCatsBlanco"}
                                        onClick={this.filtro.bind(this, "1")}>Gastos</Button>
                                </div>
                                <div className="col-auto">
                                    <Button className={this.state.activarIng == true ? "buttonCats" : "buttonCatsBlanco"}
                                        onClick={this.filtro.bind(this, "2")}>Ingresos</Button>
                                </div>
                                <div className="col-auto">
                                    <Button className={"buttonCats"} style={{ width: "100%" }}
                                        onClick={this.newCategoria}>Nueva Categoría</Button>
                                </div>
                            </div>


                            <div className="shr "></div>
                            <br />


                            <div className="divhorizontal ">

                                <div className="row srows">

                                    <div className="col-1 textocentro sinpadding">
                                    </div>
                                    <div className="col-4 sinpadding" onClick={() => this.ordenarLista('name')}>
                                        <label className="txttitlestable cursor">Nombre</label>
                                    </div>
                                    <div className="col-2 textocentro sinpadding" onClick={() => this.ordenarLista('icon')}>
                                        <label className="txttitlestable cursor">Icono</label>
                                    </div>
                                    <div className="col-2 textocentro sinpadding" onClick={() => this.ordenarLista('type')}>
                                        <label className="txttitlestable cursor">Tipo</label>
                                    </div>

                                    {this.state.txt_buscador.length > 0 && this.state.activarEgr == false && this.state.activarIng == false ?
                                        <div className="col-2 textocentro sinpadding">
                                            <label className="txttitlestable">Padre</label>
                                        </div>
                                        : null
                                    }

                                    <div className="col-1 textocentro sinpadding">
                                    </div>

                                </div>
                                <div className='divscrollhome'>

                                    {this.state.txt_buscador.length == 0 && this.state.activarEgr == false && this.state.activarIng == false ?
                                        <>
                                            {sortedData != [] ?
                                                <>
                                                    {sortedData.map((item, index) => (

                                                        <div key={index}>

                                                            {/*  <div key={index} className={count % 2 === 0 ? "row eve" : "row low"} > */}
                                                            <div key={index} className={`row ${item.collapse ? 'nuevocolorfondo' : index % 2 === 0 ? 'eve' : 'low'}`}>

                                                                <div className="col-1 spaciostabla textocentro sinpadding mostrar_cursor ">

                                                                    <div onClick={this.abrirCollage.bind(this, item, index)}>

                                                                        <label className="subtexttableRecordatorio">{(index + 1)}</label>
                                                                        {item.collapse == false ?
                                                                            <IoIosArrowDown style={{ marginLeft: "5px", cursor: "pointer" }} />
                                                                            :
                                                                            <IoIosArrowUp style={{ marginLeft: "5px", cursor: "pointer" }} />
                                                                        }
                                                                    </div>

                                                                </div>

                                                                <div className="col-4 spaciostabla  sinpadding mostrar_cursor" onClick={this.abrirCollage.bind(this, item, index)}>
                                                                    <label className="subtexttableRecordatorio">{item.name}</label>
                                                                </div>

                                                                <div className="col-2 spaciostabla textocentro sinpadding mostrar_cursor" onClick={this.abrirCollage.bind(this, item, index)}>
                                                                    <label className="subtexttableRecordatorio">{item.icon}</label>
                                                                </div>



                                                                <div className="col-2 spaciostabla textocentro sinpadding mostrar_cursor" onClick={this.abrirCollage.bind(this, item, index)}>
                                                                    <label className="subtexttableRecordatorio" style={{
                                                                        fontWeight: item.type == "income" ? "bold" : "normal",
                                                                        color: item.type == "income" && item.collapse == false ? "black" : ""
                                                                    }}>{item.type == "income" ? 'Ingresos' : "Egresos"}</label>

                                                                </div>


                                                                <div className="col-1 spaciostabla textocentro sinpadding ">

                                                                    <CiEdit className="icoedit" style={{ color: item.collapse ? "white" : "" }} onClick={this.cerrarmodalCat.bind(this, 1, item)} />
                                                               {/*     <FaTrashAlt className="icodelate" onClick={this.borrarArray.bind(this, item, index)} />   ----------------  */}

                                                                </div>


                                                            </div>

                                                            <Collapse isOpen={item.collapse} >

                                                                <div className=" bordertable">
                                                                    <br />


                                                                    <div className="row">
                                                                        <div className="col-1"></div>
                                                                        <div className="col-9">



                                                                            <div className="row">
                                                                                <div className="col">
                                                                                    <label className="txttitlestable"><b>Tienes: {item.subcategorias.length} Sub Categorías</b></label>
                                                                                </div>


                                                                                {/* ----------------  */}
                                                                                <div className="col-auto">
                                                                                    {item.subcategorias.length > 2 ?
                                                                                        (<Button className={"buttonCats"} style={{ marginBottom: "3px", width: "110px" }}
                                                                                            onClick={this.movercategorias.bind(this, 2, item)}>Mover Todas</Button>)
                                                                                        : null}
                                                                                </div>

                                                                                <div className="col-auto">
                                                                                    <Button className={"buttonCats"} style={{ marginBottom: "3px" }} onClick={this.cerrarmodalCat.bind(this, 0, item)}>Agregar</Button>
                                                                                </div>
                                                                                {/* ----------------  */}





                                                                            </div>



                                                                            <div className="row SubCatFondo">

                                                                                <div className="col-1 textocentro sinpadding">
                                                                                </div>

                                                                                <div className="col-5 sinpadding" onClick={() => this.ordenarSubcategorias(item, "name")}>
                                                                                    <label className="txttitlestable cursor">Nombre</label>
                                                                                </div>

                                                                                <div className="col-3 textocentro sinpadding" onClick={() => this.ordenarSubcategorias(item, "icon")}>
                                                                                    <label className="txttitlestable cursor">Icono</label>
                                                                                </div>

                                                                                <div className="col-2 textocentro sinpadding" onClick={() => this.ordenarSubcategorias(item, "type")}>
                                                                                    <label className="txttitlestable">Tipo</label>
                                                                                </div>

                                                                                <div className="col-1 textocentro sinpadding">
                                                                                </div>

                                                                            </div>


                                                                            <>
                                                                                {item.subcategorias != [] ?
                                                                                    <>
                                                                                        {item.subcategorias.map((item, subindex) => (



                                                                                            <div key={subindex} className={`row ${item.seleccionar ? 'nuevocolorfondo' : subindex % 2 === 0 ? 'fongris' : 'fonblan'}`}>

                                                                                                {/*<div key={subindex} className={subindex % 2 === 0 ? "row fongris" : "row fonblan"}>*/}


                                                                                                <div className="col-1 spaciostabla textocentro sinpadding mostrar_cursor" onClick={this.seleccionarTablaDatos.bind(this, item.idcategoria, index, "subcat")} >
                                                                                                    <label className="subtexttableRecordatorio">{(subindex + 1)}</label>

                                                                                                </div>
                                                                                                <div className="col-5 spaciostabla  sinpadding mostrar_cursor" onClick={this.seleccionarTablaDatos.bind(this, item.idcategoria, index, "subcat")} >
                                                                                                    <label className="subtexttableRecordatorio"><b>{item.name}</b></label><br />
                                                                                                    <label className="subtexttableRecordatorio" style={{ paddingLeft: "13px" }}>{item.description}</label>
                                                                                                </div>

                                                                                                <div className="col-3 spaciostabla textocentro sinpadding mostrar_cursor" onClick={this.seleccionarTablaDatos.bind(this, item.idcategoria, index, "subcat")} >
                                                                                                    <label className="subtexttableRecordatorio">{item.icon}</label>
                                                                                                </div>

                                                                                                <div className="col-2 spaciostabla textocentro sinpadding mostrar_cursor" onClick={this.seleccionarTablaDatos.bind(this, item.idcategoria, index, "subcat")} >
                                                                                                    <label className="subtexttableRecordatorio">{item.type == "income" ? 'Ingresos' : "Egresos"}</label>
                                                                                                </div>



                                                                                                <div className="col-1 spaciostabla textocentro sinpadding ">
                                                                                                    <CiEdit className="icoedit" style={{ color: item.seleccionar ? "white" : "" }} onClick={this.cerrarmodalCat.bind(this, 1, item)} />
                                                                                                    <FaTrashAlt className="icodelate" onClick={this.borrarArray.bind(this, item, subindex)} /> {/* ----------------  */}
                                                                                                </div>
                                                                                            </div>

                                                                                        ))}

                                                                                        {/*
                                                                                                <div className="row">
                                                                                                    <div className="col"></div>
                                                                                                    <div className="col-auto">
                                                                                                        {this.state.array_subCat.length >= 2 ? (<Button className={"buttonCats"} style={{ marginTop: "3px" }} onClick={this.cerrarmodalCat.bind(this, 0, item)}>Agregar</Button>) : null}
                                                                                                    </div>
                                                                                                </div>
                                                                                                */}

                                                                                    </>
                                                                                    : <></>
                                                                                }
                                                                            </>

                                                                        </div>
                                                                    </div>

                                                                    <br />
                                                                </div>

                                                            </Collapse>

                                                        </div>

                                                    ))}
                                                </>
                                                :
                                                <>
                                                    <label style={{ width: "100%", textAlign: "center", fontSize: "large", color: "black" }}>Sin Categorias</label>
                                                </>
                                            }
                                        </>
                                        :
                                        <>
                                            {this.state.array_filtro != [] ?
                                                <>
                                                    {this.state.array_filtro.map((item, indexx) => (

                                                        <div key={indexx}>


                                                            <div className={`row ${item.collapse ? 'nuevocolorfondo' : indexx % 2 === 0 ? 'eve' : 'low'}`}>


                                                                <div className="col-1 spaciostabla textocentro sinpadding ">


                                                                    <label className="subtexttableRecordatorio">{(indexx + 1)}</label>
                                                                    {item.parent == "" ?
                                                                        <>
                                                                            {item.collapse == false ?
                                                                                <IoIosArrowDown style={{ marginLeft: "5px", cursor: "pointer" }} />
                                                                                :
                                                                                this.state.txt_buscador.length == 0 ?
                                                                                <IoIosArrowUp style={{ marginLeft: "5px", cursor: "pointer" }} />
                                                                            :null
                                                                            
                                                                            }
                                                                        </>
                                                                        : null
                                                                    }
                                                                </div>

                                                                <div className="col-4 spaciostabla  sinpadding " onClick={this.abrirCollageFiltro.bind(this, item, indexx)}>
                                                                    <label className="subtexttableRecordatorio">{item.name}</label>
                                                                </div>

                                                                <div className="col-2 spaciostabla textocentro sinpadding" onClick={this.abrirCollageFiltro.bind(this, item, indexx)}>
                                                                    <label className="subtexttableRecordatorio">{item.icon}</label>
                                                                </div>



                                                                <div className="col-2 spaciostabla textocentro sinpadding" onClick={this.abrirCollageFiltro.bind(this, item, indexx)}>
                                                                    <label className="subtexttableRecordatorio" style={{ fontWeight: "bold", color: item.type == "income" && item.collapse == false ? "black" : "" }}
                                                                    >{item.type == "income" ? 'Ingresos' : "Egresos"}</label>

                                                                </div>

                                                                <div className="col-2 spaciostabla textocentro sinpadding mostrar_cursor" onClick={this.abrirCollageFiltro.bind(this, item, indexx)}>
                                                                    <label className="subtexttableRecordatorio" style={{ color: item.seleccionar ? "white" : "black" }}>{item.parent == "" ? '' : this.buscarCategoriaPadre(item.parent)}</label>
                                                                </div>

                                                                <div className="col-1 spaciostabla textocentro sinpadding">
                                                                    <CiEdit className="icoedit" style={{ color: item.collapse ? "white" : "" }} onClick={this.cerrarmodalCat.bind(this, 1, item)} />
                                                                    {/* <FaTrashAlt className="icodelate" onClick={this.borrarArray.bind(this, item, indexx)} /> */}

                                                                </div>


                                                            </div>
                                                            {this.state.activarEgr == true || this.state.activarIng == true ?

                                                                <Collapse isOpen={item.collapse} >

                                                                    <div className=" bordertable">
                                                                        <br />


                                                                        <div className="row">
                                                                            <div className="col-1"></div>
                                                                            <div className="col-9">



                                                                                <div className="row">
                                                                                    <div className="col">
                                                                                        <label className="txttitlestable"><b>Tienes: {item.subcategorias.length} Sub Categorías</b></label>
                                                                                    </div>

                                                                                    {/*
                    
                        <div className="col-auto">
                            {this.state.array_subCat.length >= 2 ? (<Button className={"buttonCats"} style={{ marginBottom: "3px", width: "110px" }} onClick={this.movercategorias.bind(this, 2, item)}>Mover Todas</Button>) : null}
                        </div>
                         
                        <div className="col-auto">
                            <Button className={"buttonCats"} style={{ marginBottom: "3px" }} onClick={this.cerrarmodalCat.bind(this, 0, item)}>Agregar</Button>
                        </div>

                     */}




                                                                                </div>



                                                                                <div className="row SubCatFondo">

                                                                                    <div className="col-1 textocentro sinpadding">
                                                                                    </div>

                                                                                    <div className="col-5 sinpadding" onClick={() => this.ordenarSubcategoriasFiltro(item, "name")}>
                                                                                        <label className="txttitlestable cursor">Nombre</label>
                                                                                    </div>

                                                                                    <div className="col-3 textocentro sinpadding" onClick={() => this.ordenarSubcategoriasFiltro(item, "icon")}>
                                                                                        <label className="txttitlestable cursor">Icono</label>
                                                                                    </div>

                                                                                    <div className="col-2 textocentro sinpadding" onClick={() => this.ordenarSubcategoriasFiltro(item, "type")}>
                                                                                        <label className="txttitlestable">Tipo</label>
                                                                                    </div>

                                                                                    <div className="col-1 textocentro sinpadding">
                                                                                    </div>

                                                                                </div>

                                                                                <>
                                                                                    {item.subcategorias != [] ?
                                                                                        <>
                                                                                            {item.subcategorias.map((item, subindex) => (



                                                                                                <div key={subindex} className={`row ${item.seleccionar ? 'nuevocolorfondo' : subindex % 2 === 0 ? 'fongris' : 'fonblan'}`}>

                                                                                                    {/*<div key={subindex} className={subindex % 2 === 0 ? "row fongris" : "row fonblan"}>*/}

                                                                                                    <div className="col-1 spaciostabla textocentro sinpadding mostrar_cursor" onClick={this.seleccionarTablaDatos.bind(this, item.idcategoria, indexx, "filtro")} >
                                                                                                        <label className="subtexttableRecordatorio">{(subindex + 1)}</label>
                                                                                                    </div>
                                                                                                    <div className="col-5 spaciostabla  sinpadding mostrar_cursor" onClick={this.seleccionarTablaDatos.bind(this, item.idcategoria, indexx, "filtro")} >
                                                                                                        <label className="subtexttableRecordatorio"><b>{item.name}</b></label><br />
                                                                                                        <label className="subtexttableRecordatorio" style={{ paddingLeft: "13px" }}>{item.description}</label>
                                                                                                    </div>

                                                                                                    <div className="col-3 spaciostabla textocentro sinpadding mostrar_cursor" onClick={this.seleccionarTablaDatos.bind(this, item.idcategoria, indexx, "filtro")} >
                                                                                                        <label className="subtexttableRecordatorio">{item.icon}</label>
                                                                                                    </div>

                                                                                                    <div className="col-2 spaciostabla textocentro sinpadding mostrar_cursor" onClick={this.seleccionarTablaDatos.bind(this, item.idcategoria, indexx, "filtro")} >
                                                                                                        <label className="subtexttableRecordatorio" style={{ fontWeight: "bold", color: item.type == "income" && item.collapse == false ? "black" : "" }}
                                                                                                        >{item.type == "income" ? 'Ingresos' : "Egresos"}</label>
                                                                                                    </div>

                                                                                                    <div className="col-1 spaciostabla textocentro sinpadding ">
                                                                                                        <CiEdit className="icoedit" style={{ color: item.seleccionar ? "white" : "" }} onClick={this.cerrarmodalCat.bind(this, 1, item)} />
                                                                                                        {/*<FaTrashAlt className="icodelate" onClick={this.borrarArray.bind(this, item, subindex)} /> */}
                                                                                                    </div>
                                                                                                </div>

                                                                                            ))}

                                                                                            {/*
                                <div className="row">
                                    <div className="col"></div>
                                    <div className="col-auto">
                                        {this.state.array_subCat.length >= 2 ? (<Button className={"buttonCats"} style={{ marginTop: "3px" }} onClick={this.cerrarmodalCat.bind(this, 0, item)}>Agregar</Button>) : null}
                                    </div>
                                </div>
                                */}

                                                                                        </>
                                                                                        : <></>
                                                                                    }
                                                                                </>

                                                                            </div>
                                                                        </div>

                                                                        <br />
                                                                    </div>

                                                                </Collapse>
                                                                : null
                                                            }

                                                        </div>



                                                    ))}
                                                </>
                                                : <></>
                                            }
                                        </>}

                                </div>


                            </div>

                        </div>

                    </div>

                </div>

            </>

        )

    }

}

