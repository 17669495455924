import React from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import "./ModalDetalles.css";
import { datosDetalles, guardarCuentas, restablecePass } from "../../../servicios/api";
import SpinnerModal from "../../SpinnerModal/SpinnerModal";
import { GiCheckMark } from "react-icons/gi";
import { IoCloseSharp, IoReloadSharp } from "react-icons/io5";
import ModalMessage from "../ModalMessages/ModalMessages";
import { ToastContainer, toast } from 'react-toastify';
import { GrFormSearch } from "react-icons/gr";
import ModalSubDetalles from "./ModalSubDetalles";
import { CiEdit } from "react-icons/ci";
import AgregarUsuarios from "../AgregarUsuarios/AgregarUsuarios";
import AgregarCuenta from "../AgregarCuenta/agregarCuenta";
import { FaTrashAlt } from "react-icons/fa";
import { AiOutlineClose } from "react-icons/ai";

export default class ModalDetalles extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pagadas: 0,
            nopagadas: 0,
            datosUser: props.datosUser,
            idcuentasMensaje: 0,
            array: [],
            loanding: false,
            modalTitulo: "",
            modalMessage: "",
            txt_buscador: "",
            verMJS: false,
            sortBy: "email",
            sortOrder: 'asc',
            array_filtro: [],
            array_original: [],
            arraySubCuentas: [],
            contarDias: "",
            mostrarCorreo: false,
            verModalCuentas: false,
            verMSD: false,
            veruser: false,
            datosEditar: {},
            banderaModal: "",
            eliminarTipoduracion: "",
            itemBorrar: {},
        };
    }

    async componentDidMount(ban) {
        if (this.props.show || ban == 1) {


            try {

                this.setState({ loanding: true });
                var resp = await datosDetalles(this.state.datosUser.idcliente)

                var respqqqq = 
                {
                    "code": "0",
                    "data": [
                        {
                            "idcuentas": 60799,
                            "timestamp": 1736906247901,
                            "idtarea": 504,
                            "idpaquete": "1736906244",
                            "titulo": "Renta",
                            "descripcion": "",
                            "cantidad": "400.00",
                            "idtipo": "expense",
                            "tipo": "expense",
                            "fechainicio": "2025-01-01",
                            "frecuencia": "monthly",
                            "fechaunica": "",
                            "repeticionesporsemana": "",
                            "repetirdiasdelasemana": "",
                            "repeticionespormes": "1",
                            "repetirdiasmes": "1",
                            "repeticionesporano": "",
                            "tipoduracion": "Siempre",
                            "repetirnveses": "0",
                            "fechafin": "",
                            "pagado": "0",
                            "datePosted": "",
                            "cola": "",
                            "textobusqueda": "renta  otros (gastos varios) otros (gastos varios) ",
                            "idcat": "3a8abc32-9058-4515-9275-84cff49b8392",
                            "idsubcat": "3a8abc32-9058-4515-9275-84cff49b8392",
                            "namecat": "Otros (Gastos varios)",
                            "namesubcat": "Otros (Gastos varios)",
                            "descriptioncat": "",
                            "iconcat": "icon_common_services",
                            "colorcat": "A50000",
                            "fecha": "2025-01-15 00:39:35",
                            "eliminado": "1"
                        },
                        {
                            "idcuentas": 60800,
                            "timestamp": 1736906250450,
                            "idtarea": 505,
                            "idpaquete": "1736906244",
                            "titulo": "Renta",
                            "descripcion": "",
                            "cantidad": "400.00",
                            "idtipo": "expense",
                            "tipo": "expense",
                            "fechainicio": "2025-02-01",
                            "frecuencia": "monthly",
                            "fechaunica": "",
                            "repeticionesporsemana": "",
                            "repetirdiasdelasemana": "",
                            "repeticionespormes": "1",
                            "repetirdiasmes": "1",
                            "repeticionesporano": "",
                            "tipoduracion": "Siempre",
                            "repetirnveses": "0",
                            "fechafin": "",
                            "pagado": "0",
                            "datePosted": "",
                            "cola": "",
                            "textobusqueda": "renta  otros (gastos varios) otros (gastos varios) ",
                            "idcat": "3a8abc32-9058-4515-9275-84cff49b8392",
                            "idsubcat": "3a8abc32-9058-4515-9275-84cff49b8392",
                            "namecat": "Otros (Gastos varios)",
                            "namesubcat": "Otros (Gastos varios)",
                            "descriptioncat": "",
                            "iconcat": "icon_common_services",
                            "colorcat": "A50000",
                            "fecha": "2025-01-15 00:14:10",
                            "eliminado": "1"
                        },
                        {
                            "idcuentas": 60801,
                            "timestamp": 1736906250450,
                            "idtarea": 506,
                            "idpaquete": "1736906244",
                            "titulo": "Renta",
                            "descripcion": "",
                            "cantidad": "400.00",
                            "idtipo": "expense",
                            "tipo": "expense",
                            "fechainicio": "2025-03-01",
                            "frecuencia": "monthly",
                            "fechaunica": "",
                            "repeticionesporsemana": "",
                            "repetirdiasdelasemana": "",
                            "repeticionespormes": "1",
                            "repetirdiasmes": "1",
                            "repeticionesporano": "",
                            "tipoduracion": "Siempre",
                            "repetirnveses": "0",
                            "fechafin": "",
                            "pagado": "0",
                            "datePosted": "",
                            "cola": "",
                            "textobusqueda": "renta  otros (gastos varios) otros (gastos varios) ",
                            "idcat": "3a8abc32-9058-4515-9275-84cff49b8392",
                            "idsubcat": "3a8abc32-9058-4515-9275-84cff49b8392",
                            "namecat": "Otros (Gastos varios)",
                            "namesubcat": "Otros (Gastos varios)",
                            "descriptioncat": "",
                            "iconcat": "icon_common_services",
                            "colorcat": "A50000",
                            "fecha": "2025-01-15 00:14:42",
                            "eliminado": "1"
                        },
                        {
                            "idcuentas": 60802,
                            "timestamp": 1736906250450,
                            "idtarea": 507,
                            "idpaquete": "1736906244",
                            "titulo": "Renta",
                            "descripcion": "",
                            "cantidad": "400.00",
                            "idtipo": "expense",
                            "tipo": "expense",
                            "fechainicio": "2025-04-01",
                            "frecuencia": "monthly",
                            "fechaunica": "",
                            "repeticionesporsemana": "",
                            "repetirdiasdelasemana": "",
                            "repeticionespormes": "1",
                            "repetirdiasmes": "1",
                            "repeticionesporano": "",
                            "tipoduracion": "Siempre",
                            "repetirnveses": "0",
                            "fechafin": "",
                            "pagado": "0",
                            "datePosted": "",
                            "cola": "",
                            "textobusqueda": "",
                            "idcat": "",
                            "idsubcat": "",
                            "namecat": "",
                            "namesubcat": "",
                            "descriptioncat": "",
                            "iconcat": "",
                            "colorcat": "",
                            "fecha": "2025-01-15 00:52:53",
                            "eliminado": "1"
                        },
                        {
                            "idcuentas": 60803,
                            "timestamp": 1736906250450,
                            "idtarea": 508,
                            "idpaquete": "1736906244",
                            "titulo": "Renta",
                            "descripcion": "",
                            "cantidad": "400.00",
                            "idtipo": "expense",
                            "tipo": "expense",
                            "fechainicio": "2025-05-01",
                            "frecuencia": "monthly",
                            "fechaunica": "",
                            "repeticionesporsemana": "",
                            "repetirdiasdelasemana": "",
                            "repeticionespormes": "1",
                            "repetirdiasmes": "1",
                            "repeticionesporano": "",
                            "tipoduracion": "Siempre",
                            "repetirnveses": "0",
                            "fechafin": "",
                            "pagado": "0",
                            "datePosted": "",
                            "cola": "",
                            "textobusqueda": "renta  otros (gastos varios) otros (gastos varios) ",
                            "idcat": "3a8abc32-9058-4515-9275-84cff49b8392",
                            "idsubcat": "3a8abc32-9058-4515-9275-84cff49b8392",
                            "namecat": "Otros (Gastos varios)",
                            "namesubcat": "Otros (Gastos varios)",
                            "descriptioncat": "",
                            "iconcat": "icon_common_services",
                            "colorcat": "A50000",
                            "fecha": "2025-01-15 00:52:46",
                            "eliminado": "1"
                        },
                        {
                            "idcuentas": 60848,
                            "timestamp": 1736950058305,
                            "idtarea": 0,
                            "idpaquete": "1736949998",
                            "titulo": "Abetos",
                            "descripcion": "",
                            "cantidad": "79.00",
                            "idtipo": "income",
                            "tipo": "income",
                            "fechainicio": "2025-01-20",
                            "frecuencia": "unique",
                            "fechaunica": "2025-01-20",
                            "repeticionesporsemana": "1",
                            "repetirdiasdelasemana": "1",
                            "repeticionespormes": "1",
                            "repetirdiasmes": "1",
                            "repeticionesporano": "1",
                            "tipoduracion": "Siempre",
                            "repetirnveses": "10",
                            "fechafin": "",
                            "pagado": "0",
                            "datePosted": "",
                            "cola": "",
                            "textobusqueda": "Abetos  vivienda, local, comercial, bodega, cuarto, casa, oficina",
                            "idcat": "6",
                            "idsubcat": "5",
                            "namecat": "Compra de Vehículo",
                            "namesubcat": "Renta",
                            "descriptioncat": "Vivienda, local, comercial, bodega, cuarto, casa, oficina",
                            "iconcat": "icon_rent",
                            "colorcat": "039000",
                            "fecha": "2025-01-15 08:07:38",
                            "eliminado": "0"
                        },
                        {
                            "idcuentas": 60849,
                            "timestamp": 1736950131799,
                            "idtarea": 0,
                            "idpaquete": "1736950089",
                            "titulo": "Judith",
                            "descripcion": "",
                            "cantidad": "0.20",
                            "idtipo": "expense",
                            "tipo": "expense",
                            "fechainicio": "2025-01-20",
                            "frecuencia": "unique",
                            "fechaunica": "2025-01-20",
                            "repeticionesporsemana": "1",
                            "repetirdiasdelasemana": "1",
                            "repeticionespormes": "1",
                            "repetirdiasmes": "1",
                            "repeticionesporano": "1",
                            "tipoduracion": "Siempre",
                            "repetirnveses": "10",
                            "fechafin": "",
                            "pagado": "0",
                            "datePosted": "",
                            "cola": "",
                            "textobusqueda": "Judith",
                            "idcat": "45",
                            "idsubcat": "6",
                            "namecat": "Gastos Varios",
                            "namesubcat": "Servicio Doméstico",
                            "descriptioncat": "",
                            "iconcat": "icon_domestic_service",
                            "colorcat": "039000",
                            "fecha": "2025-01-15 08:09:32",
                            "eliminado": "0"
                        },
                        {
                            "idcuentas": 60850,
                            "timestamp": 1736950229551,
                            "idtarea": 511,
                            "idpaquete": "1736950333",
                            "titulo": "Laureles",
                            "descripcion": "",
                            "cantidad": "45.00",
                            "idtipo": "income",
                            "tipo": "income",
                            "fechainicio": "2025-01-20",
                            "frecuencia": "unique",
                            "fechaunica": "2025-01-20",
                            "repeticionesporsemana": "",
                            "repetirdiasdelasemana": "",
                            "repeticionespormes": "",
                            "repetirdiasmes": "",
                            "repeticionesporano": "",
                            "tipoduracion": "unique",
                            "repetirnveses": "0",
                            "fechafin": "",
                            "pagado": "0",
                            "datePosted": "",
                            "cola": "",
                            "textobusqueda": "laureles  otros (ingresos) otros (ingresos) ",
                            "idcat": "6e8f230c-b72a-4ac2-9689-35afff9602f1",
                            "idsubcat": "6e8f230c-b72a-4ac2-9689-35afff9602f1",
                            "namecat": "Otros (Ingresos)",
                            "namesubcat": "Otros (Ingresos)",
                            "descriptioncat": "",
                            "iconcat": "icon_common_services",
                            "colorcat": "000000",
                            "fecha": "2025-01-15 08:12:14",
                            "eliminado": "0"
                        },
                        {
                            "idcuentas": 60851,
                            "timestamp": 1736950271622,
                            "idtarea": 0,
                            "idpaquete": "1736950242",
                            "titulo": "Movistar",
                            "descripcion": "",
                            "cantidad": "1.00",
                            "idtipo": "expense",
                            "tipo": "expense",
                            "fechainicio": "2025-01-20",
                            "frecuencia": "unique",
                            "fechaunica": "2025-01-20",
                            "repeticionesporsemana": "1",
                            "repetirdiasdelasemana": "1",
                            "repeticionespormes": "1",
                            "repetirdiasmes": "1",
                            "repeticionesporano": "1",
                            "tipoduracion": "Siempre",
                            "repetirnveses": "10",
                            "fechafin": "",
                            "pagado": "0",
                            "datePosted": "",
                            "cola": "",
                            "textobusqueda": "Movistar",
                            "idcat": "45",
                            "idsubcat": "74",
                            "namecat": "Gastos Varios",
                            "namesubcat": "Otros (gastos varios)",
                            "descriptioncat": "",
                            "iconcat": "icon_common_services",
                            "colorcat": "A50000",
                            "fecha": "2025-01-15 08:11:11",
                            "eliminado": "0"
                        },
                        {
                            "idcuentas": 60852,
                            "timestamp": 1736980072458,
                            "idtarea": 0,
                            "idpaquete": "1736980043",
                            "titulo": "n veces",
                            "descripcion": "",
                            "cantidad": "2000.00",
                            "idtipo": "income",
                            "tipo": "income",
                            "fechainicio": "2025-01-15",
                            "frecuencia": "daily",
                            "fechaunica": "2025-01-15",
                            "repeticionesporsemana": "1",
                            "repetirdiasdelasemana": "1",
                            "repeticionespormes": "1",
                            "repetirdiasmes": "1",
                            "repeticionesporano": "1",
                            "tipoduracion": "Siempre",
                            "repetirnveses": "10",
                            "fechafin": "",
                            "pagado": "0",
                            "datePosted": "",
                            "cola": "",
                            "textobusqueda": "n veces",
                            "idcat": "45",
                            "idsubcat": "74",
                            "namecat": "Gastos Varios",
                            "namesubcat": "Otros (gastos varios)",
                            "descriptioncat": "",
                            "iconcat": "icon_common_services",
                            "colorcat": "A50000",
                            "fecha": "2025-01-15 16:27:52",
                            "eliminado": "0"
                        },
                        {
                            "idcuentas": 60853,
                            "timestamp": 1736980072458,
                            "idtarea": 0,
                            "idpaquete": "1736980043",
                            "titulo": "n veces",
                            "descripcion": "",
                            "cantidad": "2000.00",
                            "idtipo": "income",
                            "tipo": "income",
                            "fechainicio": "2025-01-16",
                            "frecuencia": "daily",
                            "fechaunica": "2025-01-16",
                            "repeticionesporsemana": "1",
                            "repetirdiasdelasemana": "1",
                            "repeticionespormes": "1",
                            "repetirdiasmes": "1",
                            "repeticionesporano": "1",
                            "tipoduracion": "Siempre",
                            "repetirnveses": "10",
                            "fechafin": "",
                            "pagado": "0",
                            "datePosted": "",
                            "cola": "",
                            "textobusqueda": "n veces",
                            "idcat": "45",
                            "idsubcat": "74",
                            "namecat": "Gastos Varios",
                            "namesubcat": "Otros (gastos varios)",
                            "descriptioncat": "",
                            "iconcat": "icon_common_services",
                            "colorcat": "A50000",
                            "fecha": "2025-01-15 16:27:52",
                            "eliminado": "0"
                        },
                        {
                            "idcuentas": 60854,
                            "timestamp": 1736980072458,
                            "idtarea": 0,
                            "idpaquete": "1736980043",
                            "titulo": "n veces",
                            "descripcion": "",
                            "cantidad": "2000.00",
                            "idtipo": "income",
                            "tipo": "income",
                            "fechainicio": "2025-01-17",
                            "frecuencia": "daily",
                            "fechaunica": "2025-01-17",
                            "repeticionesporsemana": "1",
                            "repetirdiasdelasemana": "1",
                            "repeticionespormes": "1",
                            "repetirdiasmes": "1",
                            "repeticionesporano": "1",
                            "tipoduracion": "Siempre",
                            "repetirnveses": "10",
                            "fechafin": "",
                            "pagado": "0",
                            "datePosted": "",
                            "cola": "",
                            "textobusqueda": "n veces",
                            "idcat": "45",
                            "idsubcat": "74",
                            "namecat": "Gastos Varios",
                            "namesubcat": "Otros (gastos varios)",
                            "descriptioncat": "",
                            "iconcat": "icon_common_services",
                            "colorcat": "A50000",
                            "fecha": "2025-01-15 16:27:52",
                            "eliminado": "0"
                        },
                        {
                            "idcuentas": 60855,
                            "timestamp": 1736980072458,
                            "idtarea": 0,
                            "idpaquete": "1736980043",
                            "titulo": "n veces",
                            "descripcion": "",
                            "cantidad": "2000.00",
                            "idtipo": "income",
                            "tipo": "income",
                            "fechainicio": "2025-01-18",
                            "frecuencia": "daily",
                            "fechaunica": "2025-01-18",
                            "repeticionesporsemana": "1",
                            "repetirdiasdelasemana": "1",
                            "repeticionespormes": "1",
                            "repetirdiasmes": "1",
                            "repeticionesporano": "1",
                            "tipoduracion": "Siempre",
                            "repetirnveses": "10",
                            "fechafin": "",
                            "pagado": "0",
                            "datePosted": "",
                            "cola": "",
                            "textobusqueda": "n veces",
                            "idcat": "45",
                            "idsubcat": "74",
                            "namecat": "Gastos Varios",
                            "namesubcat": "Otros (gastos varios)",
                            "descriptioncat": "",
                            "iconcat": "icon_common_services",
                            "colorcat": "A50000",
                            "fecha": "2025-01-15 16:27:52",
                            "eliminado": "1"
                        },
                        {
                            "idcuentas": 60856,
                            "timestamp": 1736980072458,
                            "idtarea": 0,
                            "idpaquete": "1736980043",
                            "titulo": "n veces",
                            "descripcion": "",
                            "cantidad": "2000.00",
                            "idtipo": "income",
                            "tipo": "income",
                            "fechainicio": "2025-01-19",
                            "frecuencia": "daily",
                            "fechaunica": "2025-01-19",
                            "repeticionesporsemana": "1",
                            "repetirdiasdelasemana": "1",
                            "repeticionespormes": "1",
                            "repetirdiasmes": "1",
                            "repeticionesporano": "1",
                            "tipoduracion": "Siempre",
                            "repetirnveses": "10",
                            "fechafin": "",
                            "pagado": "0",
                            "datePosted": "",
                            "cola": "",
                            "textobusqueda": "n veces",
                            "idcat": "45",
                            "idsubcat": "74",
                            "namecat": "Gastos Varios",
                            "namesubcat": "Otros (gastos varios)",
                            "descriptioncat": "",
                            "iconcat": "icon_common_services",
                            "colorcat": "A50000",
                            "fecha": "2025-01-15 16:27:52",
                            "eliminado": "0"
                        },
                        {
                            "idcuentas": 60857,
                            "timestamp": 1736980072458,
                            "idtarea": 0,
                            "idpaquete": "1736980043",
                            "titulo": "n veces",
                            "descripcion": "",
                            "cantidad": "2000.00",
                            "idtipo": "income",
                            "tipo": "income",
                            "fechainicio": "2025-01-20",
                            "frecuencia": "daily",
                            "fechaunica": "2025-01-20",
                            "repeticionesporsemana": "1",
                            "repetirdiasdelasemana": "1",
                            "repeticionespormes": "1",
                            "repetirdiasmes": "1",
                            "repeticionesporano": "1",
                            "tipoduracion": "Siempre",
                            "repetirnveses": "10",
                            "fechafin": "",
                            "pagado": "0",
                            "datePosted": "",
                            "cola": "",
                            "textobusqueda": "n veces",
                            "idcat": "45",
                            "idsubcat": "74",
                            "namecat": "Gastos Varios",
                            "namesubcat": "Otros (gastos varios)",
                            "descriptioncat": "",
                            "iconcat": "icon_common_services",
                            "colorcat": "A50000",
                            "fecha": "2025-01-15 16:27:52",
                            "eliminado": "0"
                        },
                        {
                            "idcuentas": 60858,
                            "timestamp": 1736980072458,
                            "idtarea": 0,
                            "idpaquete": "1736980043",
                            "titulo": "n veces",
                            "descripcion": "",
                            "cantidad": "2000.00",
                            "idtipo": "income",
                            "tipo": "income",
                            "fechainicio": "2025-01-21",
                            "frecuencia": "daily",
                            "fechaunica": "2025-01-21",
                            "repeticionesporsemana": "1",
                            "repetirdiasdelasemana": "1",
                            "repeticionespormes": "1",
                            "repetirdiasmes": "1",
                            "repeticionesporano": "1",
                            "tipoduracion": "Siempre",
                            "repetirnveses": "10",
                            "fechafin": "",
                            "pagado": "0",
                            "datePosted": "",
                            "cola": "",
                            "textobusqueda": "n veces",
                            "idcat": "45",
                            "idsubcat": "74",
                            "namecat": "Gastos Varios",
                            "namesubcat": "Otros (gastos varios)",
                            "descriptioncat": "",
                            "iconcat": "icon_common_services",
                            "colorcat": "A50000",
                            "fecha": "2025-01-15 16:27:52",
                            "eliminado": "1"
                        },
                        {
                            "idcuentas": 60859,
                            "timestamp": 1736980072458,
                            "idtarea": 0,
                            "idpaquete": "1736980043",
                            "titulo": "n veces",
                            "descripcion": "",
                            "cantidad": "2000.00",
                            "idtipo": "income",
                            "tipo": "income",
                            "fechainicio": "2025-01-22",
                            "frecuencia": "daily",
                            "fechaunica": "2025-01-22",
                            "repeticionesporsemana": "1",
                            "repetirdiasdelasemana": "1",
                            "repeticionespormes": "1",
                            "repetirdiasmes": "1",
                            "repeticionesporano": "1",
                            "tipoduracion": "Siempre",
                            "repetirnveses": "10",
                            "fechafin": "",
                            "pagado": "0",
                            "datePosted": "",
                            "cola": "",
                            "textobusqueda": "n veces",
                            "idcat": "45",
                            "idsubcat": "74",
                            "namecat": "Gastos Varios",
                            "namesubcat": "Otros (gastos varios)",
                            "descriptioncat": "",
                            "iconcat": "icon_common_services",
                            "colorcat": "A50000",
                            "fecha": "2025-01-15 16:27:52",
                            "eliminado": "1"
                        },
                        {
                            "idcuentas": 60860,
                            "timestamp": 1736980072458,
                            "idtarea": 0,
                            "idpaquete": "1736980043",
                            "titulo": "n veces",
                            "descripcion": "",
                            "cantidad": "2000.00",
                            "idtipo": "income",
                            "tipo": "income",
                            "fechainicio": "2025-01-23",
                            "frecuencia": "daily",
                            "fechaunica": "2025-01-23",
                            "repeticionesporsemana": "1",
                            "repetirdiasdelasemana": "1",
                            "repeticionespormes": "1",
                            "repetirdiasmes": "1",
                            "repeticionesporano": "1",
                            "tipoduracion": "Siempre",
                            "repetirnveses": "10",
                            "fechafin": "",
                            "pagado": "0",
                            "datePosted": "",
                            "cola": "",
                            "textobusqueda": "n veces",
                            "idcat": "45",
                            "idsubcat": "74",
                            "namecat": "Gastos Varios",
                            "namesubcat": "Otros (gastos varios)",
                            "descriptioncat": "",
                            "iconcat": "icon_common_services",
                            "colorcat": "A50000",
                            "fecha": "2025-01-15 16:27:52",
                            "eliminado": "0"
                        },
                        {
                            "idcuentas": 60861,
                            "timestamp": 1736980072458,
                            "idtarea": 0,
                            "idpaquete": "1736980043",
                            "titulo": "n veces",
                            "descripcion": "",
                            "cantidad": "2000.00",
                            "idtipo": "income",
                            "tipo": "income",
                            "fechainicio": "2025-01-24",
                            "frecuencia": "daily",
                            "fechaunica": "2025-01-24",
                            "repeticionesporsemana": "1",
                            "repetirdiasdelasemana": "1",
                            "repeticionespormes": "1",
                            "repetirdiasmes": "1",
                            "repeticionesporano": "1",
                            "tipoduracion": "Siempre",
                            "repetirnveses": "10",
                            "fechafin": "",
                            "pagado": "0",
                            "datePosted": "",
                            "cola": "",
                            "textobusqueda": "n veces",
                            "idcat": "45",
                            "idsubcat": "74",
                            "namecat": "Gastos Varios",
                            "namesubcat": "Otros (gastos varios)",
                            "descriptioncat": "",
                            "iconcat": "icon_common_services",
                            "colorcat": "A50000",
                            "fecha": "2025-01-15 16:27:52",
                            "eliminado": "0"
                        }
                    ],
                    "message": "Successful"
                }


                if (resp == 0) {

                }
                else {

                    var tarray = resp.data
                    var arrayt = resp.data// tarray.filter(item => item.eliminado === "0");
                    let pagadas = 0, nopagadas = 0;
                    for (let i = 0; i < arrayt.length; i++) {
                        arrayt[i].seleccionar = false;
                        arrayt[i].total = 0;

                        if (arrayt[i].pagado === "0") {
                            pagadas++;
                        }
                        else {
                            nopagadas++;
                        }

                    }


                    var contarTotal = arrayt.reduce((acc, item) => {
                        acc[item.idpaquete] = (acc[item.idpaquete] || 0) + 1;
                        return acc;
                    }, {});

                    const datosFiltradosConTotal = arrayt.reduce((acc, current) => {
                        var existe = acc.find(item => item.idpaquete === current.idpaquete);
                        if (!existe) {
                            acc.push({
                                ...current,
                                total: contarTotal[current.idpaquete]
                            });
                        }
                        return acc;
                    }, []);

                    const ultimoLogin = this.state.datosUser.ultimasincronizacion;
                    const dias = this.calcularDiasTranscurridos(ultimoLogin);
                    var contadrdias = "";
                    if (dias == 1) {
                        contadrdias = "*Hace: " + dias + " día";
                    }
                    else if (dias == 0) {
                        contadrdias = "*Hace: " + dias + " días";
                    }
                    else { 
                        contadrdias = "*Hace: " + dias + " días";
                    }

                    this.setState({ array: datosFiltradosConTotal, array_original: arrayt, pagadas: pagadas, nopagadas: nopagadas, contarDias: contadrdias });
                }
                this.setState({ loanding: false })
            } catch (error) {
                //console.error("Error al cargar datos:", error);
                this.setState({ loanding: false });
            }
        }

    }

    toggleCorreo = () => {
        this.setState(prevState => ({
            mostrarCorreo: !prevState.mostrarCorreo
        }));
    };

    closemodal = (response, idcliente) => {
        this.setState({ show: false });
        this.props.cerrarmodal(response, this.state.bandera, idcliente);
    }

    formatearFecha = (fecha) => {
        const [year, month, day] = fecha.split("-").map(Number);
        const date = new Date(year, month - 1, day);
        const dia = date.getDate();
        const mes = date.toLocaleString('es-ES', { month: 'short' });
        const yearFinal = date.getFullYear();
        return `${dia}/${mes}/${yearFinal}`;
    };


    seleccionarTabla = (idcuentas, idpaquete) => {
        const { array } = this.state;
        const newData = [];
        var estrella = 1;
        for (let i = 0; i < array.length; i++) {
            const item = array[i];
            if (item.idcuentas === idcuentas) {
                newData.push({ ...item, seleccionar: !item.seleccionar });
                estrella = 0;
            } else {
                newData.push({ ...item, seleccionar: false });
            }
        }
        this.setState({ array: newData });

        if (estrella == 0) {
            this.OpenModalSubDetalles(idpaquete)
        }

    }

    editarRegistro = (item) => {
        console.log(JSON.stringify(item))
        /*
        {(this.state.verModalCuentas == true) ? (
            <AgregarCuenta show={this.state.verModalCuentas} infoModal={this.state.datosUser} bandera={this.state.banderaModal}
                cerrarmodal={this.closeEditar} />
        ) : null}*/

        this.setState({ banderaModal: "editar", datosEditar: item, verModalCuentas: !this.state.verModalCuentas, });

    }


    borrarArray = async (dato) => {

        if (dato == 0) {
            this.setState({ verMJS: !this.state.verMJS })
        }
        else if (dato == 1) {

            this.setState({ loanding: true, verMJS: !this.state.verMJS })
            var infoModal = this.state.datosUser;
            var json1 = {};

            if (this.state.eliminarTipoduracion == "Siempre") {
                var item = this.state.itemBorrar;
                var arrayTemp = [];
                arrayTemp.push({
                    "idcuentas": item.idcuentas,
                    "idtarea": item.idtarea,
                    "idpaquete": item.idpaquete,
                    "titulo": item.titulo,
                    "descripcion": item.descripcion,
                    "cantidad": item.cantidad,
                    "idtipo": item.idtipo,
                    "tipo": item.idtipo,
                    "fechainicio": item.fechainicio,
                    "frecuencia": item.frecuencia,
                    "fechaunica": item.fechaunica,
                    "repeticionesporsemana": item.repeticionesporsemana,
                    "repetirdiasdelasemana": item.repetirdiasdelasemana,
                    "repeticionespormes": item.repeticionespormes,
                    "repetirdiasmes": item.repetirdiasmes,
                    "repeticionesporano": item.repeticionesporano,
                    "tipoduracion": item.tipoduracion,
                    "repetirnveses": item.repetirnveses,
                    "fechafin": item.fechafin,
                    "pagado": "0",
                    "datePosted": "",
                    "cola": "",
                    "textobusqueda": "",

                    "idcat": "",
                    "idsubcat": "", // "3a8abc32-9058-4515-9275-84cff49b8392",
                    "namecat": "",
                    "namesubcat": "",
                    "descriptioncat": "",
                    "iconcat": "",
                    "colorcat": "",
                    "eliminado": "1"

                })

                json1 = {
                    "token": infoModal.token,
                    //"iddispositivo": "123123213213",
                    "cuentas": arrayTemp,
                    "historial": [],
                    "eliminados": []
                }

            }
            else {

                var arrayDelete = [];
                arrayDelete.push({ "idregistro": this.state.idcuentasMensaje, "pagina": "home" })
                json1 = {
                    "token": infoModal.token,
                    "eliminados": arrayDelete,
                    "historial": [
                    ],
                    "cuentas": [
                    ]

                }

            }
            console.log(json1)
            var resp = await guardarCuentas(json1)
            console.log(JSON.stringify(resp))

            if (resp.code == 0) {

                this.setState({ loanding: false })


                this.componentDidMount(1);
                toast.success("Cuenta eliminada", { autoClose: 4500, position: toast.POSITION.TOP_LEFT });
            }
            else {
                // resp
                this.setState({ loanding: false })
            }



        }
        else {

            console.log(dato.tipoduracion)

            this.setState({
                eliminarTipoduracion: dato.tipoduracion,
                itemBorrar: dato,
                idcuentasMensaje: dato.idcuentas,
                modalTitulo: "Eliminar cuenta",
                modalMessage: dato.titulo + ", " + this.formatearFecha(dato.fechainicio),
                tipobandera: "borrar",
                verMJS: !this.state.verMJS
            })
        }
    }



    ordenarLista = (key) => {
        if (this.state.sortBy === key) {
            // Si ya está ordenado por la misma clave, cambiar el orden
            this.setState((prevState) => ({
                sortOrder: prevState.sortOrder === 'asc' ? 'desc' : 'asc',
            }));
        } else {
            // Si es una nueva clave de ordenamiento, ordenar ascendentemente
            this.setState({
                sortBy: key,
                sortOrder: 'asc',
            });
        }
    }


    restablecer = async () => {
        this.setState({ loanding: true })
        var resp = await restablecePass(this.state.datosUser.email);
        //this.closemodal(0)


        this.setState({ loanding: false })

    }
    OpenModalSubDetalles = async (idpaquete) => {
        console.log("LLego --- " + idpaquete)
        
        if (idpaquete == 0) {
            this.setState({ verMSD: !this.state.verMSD })
        } else if (idpaquete == 1) {
            this.componentDidMount(1);
        } else {
            const resultado = this.filtrarPorIdPaquete(idpaquete);
            console.log("LLego 22 --- ")
        console.log(resultado[1])
/*
                this.setState({ loanding: true })
                var arrayDelete = [];
             //   arrayDelete = resultado;
        
                for (let i = 0; i < resultado.length; i++) {
                    arrayDelete.push({ "idregistro": resultado[i].idcuentas, "pagina": "home" })
                }
                var infoModal = this.state.datosUser;
        
                var json1 = {
                    "token": infoModal.token,
                    "eliminados": arrayDelete,
                    "historial": [
                    ],
                    "cuentas": [
                    ]
                }
        
                var resp = await guardarCuentas(json1)
                console.log(JSON.stringify(resp))
        
                if (resp.code == 0) {
        
                    this.setState({ loanding: false })
        
                    this.closemodal(1);
                    //this.componentDidMount(1);
                    toast.success("Cuenta eliminada", { autoClose: 4500, position: toast.POSITION.TOP_LEFT });
                }
                else {
                    // resp
                    this.setState({ loanding: false })
                }
                */

                
            if (resultado.length > 1) {
                this.setState({
                    modalTitulo: "Datos",
                    arraySubCuentas: JSON.stringify(resultado),
                    verMSD: !this.state.verMSD
                })
            }

  
        }

    }


    filtrarPorIdPaquete = (idPaquete) => {
        return this.state.array_original.filter(item => item.idpaquete === idPaquete);
    }

    modalAviso = async (dato) => { // ----
        if (dato == 0) {
            this.setState({ verMJS: !this.state.verMJS })
        }
        else if (dato == 1) {
            this.setState({ verMJS: !this.state.verMJS })
            await this.restablecer()
            toast.success("Se envió un Correo para restablecer la contraseña", { autoClose: 4500, position: toast.POSITION.TOP_LEFT });
        }
        else {

            var correo = "";
            if (this.state.datosUser.email != "") {
                correo = this.state.datosUser.email[0] + '...' + this.state.datosUser.email.substring(this.state.datosUser.email.indexOf('@') - 1)
                //console.log(" --------  ")
            }
            else {
                //console.log(" +++++++  ")
                correo = " - Invitado"
            }

            this.setState({
                modalTitulo: "Recuperar contraseña",
                modalMessage: "*" + correo + "*",
                tipobandera: "cambiarContrasena",
                verMJS: !this.state.verMJS
            })
        }

    }

    save_input_locacion = async (e) => {

        var filtropuesto = this.state.array;
        for (let i = 0; i < filtropuesto.length; i++) {
            filtropuesto[i].seleccionar = false;
        }
        this.setState({ array: filtropuesto })
        var cadenain = e.target.value.trim()
        this.setState({ txt_buscador: cadenain });
        var filtrofinal = [];

        if (cadenain.toLocaleLowerCase().length >= 1) {
            filtrofinal = filtropuesto.filter((item) => item.titulo.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase())
                || item.textobusqueda.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase()))

            if (filtrofinal.length <= 0) {
                this.setState({ array_filtro: [] })
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                this.setState({ array_filtro: filtrofinal })
            }
        }

    }

    editarUser = () => {
        console.log(JSON.stringify(this.state.datosUser))
        this.setState({ banderaModal: "clientes", veruser: !this.state.veruser, });
    }


    agregarNuevo = (pos) => {
        console.log("Arias ---- ")
        if (pos == 2) {
            this.setState({ banderaModal: "nuevo", verModalCuentas: true });
        }
        else if (pos == 1) {
            this.componentDidMount(1);
        }
        else {
            this.setState({ verModalCuentas: false });
        }
    }

    closeEditar = (response) => {
        this.setState({ veruser: false });
        if (response == 1) {
            // this.componentDidMount();
            this.closemodal(2, this.state.datosUser.idcliente)
        }
        //this.props.cerrarmodal(response, this.state.bandera);
    }

    calcularDiasTranscurridos = (fecha) => {
        const fechaA = new Date(fecha.split(" ")[0]);
        const fechaB = new Date(new Date().toISOString().split("T")[0]);
        const diferenciaEnMilisegundos = fechaB - fechaA;
        const diasTranscurridos = Math.floor(diferenciaEnMilisegundos / (1000 * 60 * 60 * 24));
        return diasTranscurridos;

    }

    render() {

        const { array, sortBy, sortOrder } = this.state;

        // Función para ordenar los datos según la clave y el orden especificados
        const sortedData = [...array].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[sortBy] > b[sortBy] ? 1 : -1;
            } else {
                return a[sortBy] < b[sortBy] ? 1 : -1;
            }
        });


        if (!this.props.show) return null;
        return (
            <>


                <ToastContainer />

                {(this.state.loanding == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loanding} />
                ) : null}

                {(this.state.verMJS == true) ? (
                    <ModalMessage show={this.state.verMJS} titulo={this.state.modalTitulo}
                        bandera={this.state.tipobandera} mensaje={this.state.modalMessage}
                        cerrarmodal={this.modalAviso} cerrarBorrar={this.borrarArray} />
                ) : null}

                {(this.state.verMSD == true) ? (
                    <ModalSubDetalles show={this.state.verMSD} titulo={this.state.modalTitulo} bandera={this.state.banderaModal}
                        cerrarmodal={this.OpenModalSubDetalles} array={this.state.arraySubCuentas} infoModal={this.state.datosUser} />
                ) : null}

                {(this.state.veruser == true) ? (
                    <AgregarUsuarios show={this.state.veruser} infoModal={this.state.datosUser} bandera={this.state.banderaModal}
                        cerrarmodal={this.closeEditar} />
                ) : null}

                {(this.state.verModalCuentas == true) ? (
                    <AgregarCuenta show={this.state.verModalCuentas} infoModal={this.state.datosUser} bandera={this.state.banderaModal}
                        cerrarmodal={this.agregarNuevo} datos={this.state.datosEditar} />
                ) : null}



                <Modal isOpen={this.props.show}
                    size="xl"
                    aria-labelledby=""
                    centered
                    toggle={this.closemodal.bind(this, 0)}
                    className="modal-messages">
                    <ModalBody className="bodybackgroundblack">

                        <div className="row" >
                            <div className="col">
                                <label style={{ fontSize: "25px", textAlign: "center", width: "100%", color: "black" }}>Detalles del cliente</label>
                            </div>
                            <div className="col-auto">
                                <label className="textocerrarlbl" ><AiOutlineClose style={{ cursor: "pointer" }}
                                    onClick={this.closemodal.bind(this, 0)} /></label>
                            </div>
                        </div>
                        <br />

                        <div className="row">

                            <div className="col-4">
                                <p style={{ fontSize: "17px" }}>
                                    <b style={{ color: "black" }}>
                                        Nombre: <span style={{ fontWeight: "normal" }}>{this.state.datosUser.nombre}</span>
                                    </b>
                                    <br />
                                    <b style={{ color: "black" }}>
                                        Correo:{" "}
                                        <span style={{ fontWeight: "normal" }}>
                                            {this.state.mostrarCorreo
                                                ? this.state.datosUser.email
                                                : this.state.datosUser.email &&
                                                    this.state.datosUser.email.length > 0
                                                    ? this.state.datosUser.email[0] +
                                                    "..." +
                                                    this.state.datosUser.email.substring(
                                                        this.state.datosUser.email.indexOf("@") - 1
                                                    )
                                                    : "-----"}
                                        </span>
                                    </b>
                                </p>
                                {/* this.state.datosUser.tipocliente == "Registrado" ?
                                    <button className="lblcorreo" onClick={this.toggleCorreo}>
                                        {this.state.mostrarCorreo ? (
                                            <IoMdEyeOff style={{ fontSize: "20px" }} />
                                        ) : (
                                            <IoMdEye style={{ fontSize: "20px" }} />
                                        )}
                                        {this.state.mostrarCorreo ? "Ocultar correo" : "Mostrar correo"}
                                    </button>
                                    : null
                                        */}
                            </div>


                            <div className="col-4">
                                <p><b style={{ color: "black" }}>Tipo: {this.state.datosUser.tipocliente}</b></p>
                                {this.state.datosUser.tipocliente == "Registrado" ?
                                    <Button className="btncorreoser" onClick={this.editarUser}><CiEdit style={{ marginRight: "5px" }} />Editar Cliente</Button>
                                    : null}
                            </div>

                            <div className="col-4">
                                <p style={{ fontSize: "17px" }}><b style={{ color: "black" }}>Fecha registro: {this.state.datosUser.registro}</b><br />
                                    <b style={{ color: "black" }}>Última sincronización: {this.state.datosUser.ultimasincronizacion}</b><br />
                                    <b style={{ color: "black", fontWeight: "normal" }}>{this.state.contarDias}</b></p>
                            </div>

                        </div>

                        <br />
                        <br />

                        <div className="row align-items-end">
                            <div className="col-auto ">
                                <div className="input-group ">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="basic-addon1"> <GrFormSearch /></span>
                                    </div>
                                    <input type="search" onChange={this.save_input_locacion} value={this.state.txt_buscador}
                                        className="form-control" aria-label="buscars" placeholder="Buscar registros" aria-describedby="basic-addon1"></input>
                                </div>
                            </div>
                            <div className="col"></div>
                            <div className="col-auto">
                                <p style={{ fontSize: "17px", marginRight: "30px" }}><b>Total cuentas: {this.state.array.length} </b></p>
                            </div>

                            <div className="col-auto">
                                <p style={{ fontSize: "17px", marginRight: "40px" }}><b>Pagadas: {this.state.datosUser.pagadas} </b></p>
                            </div>
                            {/**-------------------------------------------------------------- */}
                            {this.state.datosUser.tipocliente == "Registrado" ?
                                <>

                                    <div className="col-auto">
                                        <Button className="btncorreoserLista" onClick={this.componentDidMount.bind(this, 2)}><IoReloadSharp /></Button>
                                    </div>
                                    <div className="col-auto">
                                        <Button className="btncorreoserLista" onClick={this.agregarNuevo.bind(this, 2)}>Agregar cuenta</Button>
                                    </div>
                                </>
                                : null
                            }
                        </div>

                        <div style={{ height: "8px" }}></div>
                        <div className="row" style={{ backgroundColor: "#51a3ed", color: "black", paddingRight: "15px" }}>


                            <div className="col-2  sinpadding" onClick={() => this.ordenarLista('fechainicio')}>
                                <label className="txttitlestable cursor" style={{ paddingLeft: "5px" }}>Fecha</label>
                            </div>
                            <div className="col-3  sinpadding" onClick={() => this.ordenarLista('titulo')}>
                                <label className="txttitlestable cursor">Nombre</label>
                            </div>
                            <div className="col-2  sinpadding" onClick={() => this.ordenarLista('namesubcat')}>
                                <label className="txttitlestable cursor">Categoría</label>
                            </div>
                            <div className="col-2 textocentro sinpadding" onClick={() => this.ordenarLista('tipo')}>
                                <label className="txttitlestable cursor">Tipo</label>
                            </div>
                            <div className="col-2 textocentro sinpadding" onClick={() => this.ordenarLista('pagado')}>
                                <label className="txttitlestable cursor">Pagado</label>
                            </div>
                            <div className="col-1 textocentro sinpadding">
                            </div>

                        </div>
                        <div className='divscrollhome' style={{ maxHeight: "600px", minHeight: "100px" }} >

                            {this.state.txt_buscador.length == 0 ?
                                <>{sortedData.length > 0 ? <>
                                    {sortedData.map((item, index) => (

                                        <div className={`row ${item.seleccionar ? 'nuevocolorfondo' : index % 2 === 0 ? 'eve' : 'low'}`}
                                            onClick={this.seleccionarTabla.bind(this, item.idcuentas, item.idpaquete)}>

                                            <div className="col-2 spaciostabla ">
                                                <label className="subtexttableRecordatorio" style={{ paddingLeft: "5px" }}>{this.formatearFecha(item.fechainicio)}</label>
                                            </div>

                                            <div className="col-3 spaciostabla sinpadding">
                                                <div className="row sinpadding">
                                                    <div className="col-auto sinpadding">
                                                        <label style={{ width: item.total.length > 1 ? "35px" : "25px", fontWeight: "bold" }}>{"(" + item.total + ")"}</label>
                                                    </div>
                                                    <div className="col sinpadding">
                                                        <label className="subtexttableRecordatorio">
                                                            {item.titulo != ""
                                                                ? " - " + item.titulo : "-----"}</label>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-2 spaciostabla sinpadding">
                                                <label className="subtexttableRecordatorio">{item.namesubcat}</label>
                                            </div>

                                            <div className="col-2 spaciostabla textocentro sinpadding">
                                                <label className="subtexttableRecordatorio">{item.tipo}</label>
                                            </div>

                                            <div className="col-2 spaciostabla textocentro sinpadding">
                                                <label className="subtexttableRecordatorio">
                                                    {item.pagado == "0" ?
                                                        <IoCloseSharp style={{ fontSize: "medium", color: "red" }} />
                                                        : <GiCheckMark style={{ fontSize: "medium", color: "green" }} />}
                                                </label>
                                            </div>

                                            {this.state.datosUser.tipocliente == "Registrado" ?
                                                <div className="col-1 spaciostabla textocentro sinpadding ">
                                                    {item.total == 1 && item.pagado == "0" ?
                                                        <>
                                                            <CiEdit className="icoedit" style={{ color: item.seleccionar ? "white" : "" }} onClick={this.editarRegistro.bind(this, item)} />
                                                            <FaTrashAlt className="icodelate" onClick={this.borrarArray.bind(this, item, 0)} />
                                                        </>
                                                        : null}
                                                </div>
                                                : null
                                            }

                                        </div>
                                    ))}
                                </> :
                                    <div className="row" >
                                        <div className="col-12">
                                            <br />
                                            <p style={{ fontSize: "16px", color: "black", width: "100%", textAlign: "center" }}><b > Sin registros </b></p>
                                        </div>
                                    </div>
                                }
                                </>
                                :
                                <>
                                    {this.state.array_filtro.map((item, index) => (


                                        <div key={index} className={`row ${item.seleccionar ? 'nuevocolorfondo' : index % 2 === 0 ? 'eve' : 'low'}`}
                                            onClick={this.seleccionarTabla.bind(this, item.idcuentas, item.idpaquete)}>

                                            <div className="col-2 spaciostabla ">
                                                <label className="subtexttableRecordatorio" style={{ paddingLeft: "5px" }}>{this.formatearFecha(item.fechainicio)}</label>
                                            </div>

                                            <div className="col-3 spaciostabla sinpadding">
                                                <label className="subtexttableRecordatorio">{item.titulo != "" ? item.titulo : "-----"}</label>
                                            </div>

                                            <div className="col-2 spaciostabla sinpadding">
                                                <label className="subtexttableRecordatorio">{item.namesubcat}</label>
                                            </div>

                                            <div className="col-2 spaciostabla textocentro sinpadding">
                                                <label className="subtexttableRecordatorio">{item.tipo}</label>
                                            </div>

                                            <div className="col-2 spaciostabla textocentro sinpadding">
                                                <label className="subtexttableRecordatorio">
                                                    {item.pagado == "0" ?
                                                        <IoCloseSharp style={{ fontSize: "medium", color: "red" }} />
                                                        : <GiCheckMark style={{ fontSize: "medium", color: "green" }} />}
                                                </label>
                                            </div>

                                            <div className="col-1 spaciostabla textocentro sinpadding ">
                                                {item.total == 1 ?
                                                    <>
                                                        <CiEdit className="icoedit" style={{ color: item.seleccionar ? "white" : "" }} onClick={this.editarRegistro.bind(this, item)} />
                                                        <FaTrashAlt className="icodelate" onClick={this.borrarArray.bind(this, item, 1)} />
                                                    </>
                                                    : null}
                                            </div>

                                        </div>


                                    ))}
                                </>}



                        </div>
                        <br />
                        <div className="modalmessage-footer">
                            <button className="buttonbase message-alert-ok" onClick={() => this.closemodal(0)}>Cerrar</button>
                            {this.state.datosUser.email != "" ?
                                <button className="buttonshadow message-alert-cancel" style={{ width: "190px" }}
                                    onClick={this.modalAviso.bind(this, 2)}>Restablecer contraseña</button>
                                : null}

                        </div>

                    </ModalBody>

                </Modal>
            </>
        )

    }
}