import React from "react";
import { Modal, ModalBody } from "reactstrap";
import "./ModalMessages.css"

export default class ModalMessage extends React.Component {

    constructor(props) {
        super(props)
        //console.log(this.props);
        this.state = {
            show: this.props.show,
            titulo: this.props.titulo,
            mensaje: this.props.mensaje,
            botones: this.props.options,
        }
    }

    closemodal = (response) => {
        this.setState({ show: false });
        if (this.props.bandera === "cambiarContrasena" || this.props.bandera === "eliminarCliente" || this.props.bandera === "restaurar") {
            this.props.cerrarmodal(response);
        } else {
            this.props.cerrarBorrar(response)
        }
    }

    render() {
        return (
            <>
                <Modal isOpen={this.state.show}
                    size="sm"
                    aria-labelledby=""
                    centered
                    toggle={this.closemodal.bind(this, 0)}
                    className="modal-messages">
                    <ModalBody className="bodybackgroundblack">
                        <div className="modalmessage-header" style={{ textAlign: "center", color: "black" }}>
                            <p><b> {this.state.titulo} </b></p>
                        </div>
                        <br />
                        {this.props.bandera == "cambiarContrasena" ?
                            <div className="modalmessage-body">
                                <p>Se enviaran las instrucciones para recuperar la contraseña ha:<b style={{ color: "black" }}>{this.state.mensaje}</b></p>
                            </div>
                            :
                            this.props.bandera == "restaurar" ?
                                <div className="modalmessage-body">
                                    <p>Deseas restaurar <b style={{ color: "black" }}>{this.state.mensaje}</b></p>
                                </div>
                                :
                                <div className="modalmessage-body">
                                    <p>Deseas eliminar <b style={{ color: "black" }}>{this.state.mensaje}</b></p>
                                </div>
                        }
                        <br />
                        <div className="modalmessage-footer">
                            <button className="buttonshadow message-alert-cancel " onClick={() => this.closemodal(0)}>Cancelar</button>


                            <button className="buttonbase message-alert-ok" onClick={() => this.closemodal(1)}>Continuar</button>
                        </div>
                    </ModalBody>
                </Modal>
            </>

        )
    }
}

