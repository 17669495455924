import React from 'react'
import { Modal, ModalBody } from 'reactstrap';
import { AiOutlineClose } from "react-icons/ai";
import '../Login.css';
import { Button } from "@mui/material";
import { recuperarPass } from '../../../servicios/api';

export default class RecuperarPassword extends React.Component {

  constructor(props) {
    super(props)

    this.state = {
      show: props.show,
      email: '',
      Valid:false,
      isValid: false
    }

  }

  handleChange = (e) => {
    this.setState({ email: e.target.value });
  }

  hacerlogin = async () => {

    console.log(this.state.email)
    if (this.validateEmail(this.state.email)) {
      // Aquí puedes implementar la lógica para enviar la solicitud de recuperación de contraseña
      console.log('Correo electrónico enviado:', this.state.email);


      var resp = await recuperarPass(this.state.email.trim())

      this.setState({ isValid: false });
      this.setState({ Valid: true })
      // Cerrar el modal después de enviar la solicitud

    } else {
      console.log('Correo electrónico NO enviado:', this.state.email);
      // Si el correo electrónico no es válido, establecer el estado isValid en falso
      this.setState({ Valid: false });
      this.setState({ isValid: true });
      
    }


  }
  validateEmail = (email) => {
    // Expresión regular para validar el correo electrónico
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regex.test(email);
  }

  closemodal = (ban) => {
    this.props.cerrarmodal(ban);
    this.setState({ show: false });
  }

  render() {


    return (


      <>


        <Modal isOpen={this.state.show}
          size="md"
          aria-labelledby=""
          centered>
          <ModalBody>

            <div className="divconpass">


              <div className="row" >
                <div className="col-auto ">
                  <label className="lbltitlesModal" style={{ fontSize: "large" }}>Recuperar Contraseña</label>
                </div>
                <div className="col">
                  <label className="textocerrarlbl" ><AiOutlineClose onClick={this.closemodal.bind(this, 0, "-0")} /></label>
                </div>
              </div>


              <br />
              <label className="lbldespass" htmlFor="username">Por favor ingresa tu correo electrónico asociado a tu cuenta.</label>



              <br />


              <input className='txtemailpass' placeholder="example@email.com" type="email" value={this.state.email} onChange={this.handleChange} />
              {this.state.isValid && <p className="error-message">Correo electrónico inválido</p>}
              {this.state.Valid && <p className="edit-message">Enviamos un correo a: <b style={{textDecoration: "underline", color: "black"}}>{this.state.email.trim()}</b></p>}
              <br />



              <Button className='btnrespass'  onClick={this.hacerlogin} variant="contained" fullWidth >
                Enviar
              </Button>



            </div>
          </ModalBody>

        </Modal>

      </>
    );
  }
}

