import React from "react";
import MainHome from "../../pages/Home/MainHome";
import ListaCategorias from "../../pages/ListaCategoria/ListaCategorias.js";
import ListaCuentas from "../../pages/ListaCuentas/ListaCuentas";
import ListaPlanes from "../../pages/ListaPlanes/ListaPlanes";
import NavBar from "../navbar/navbar";



export default class Dashboard extends React.Component {

    constructor(props) {
        super(props);

    }

    render() {
        return (
            <>
                 <NavBar /> 
                   {this.props.ventana == "home" ?
                    <MainHome />
                    :
                    this.props.ventana == "categorias" ?
                    <ListaCategorias />
                    :
                    this.props.ventana == "clientes" ?
                    <ListaCuentas />
                    :
                    this.props.ventana == "planes" ?
                    <ListaPlanes />
                    :
                    null
                }
            </>
        )

    }
}