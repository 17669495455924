import React from "react";
import { ClockLoader } from "react-spinners";
import "./SpinnerModal.css"


export default class SpinnerModal extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <>
                <div className={(this.props.tipo === 'full') ? ("progresoSpinnerFull") : "progresoSpinner"}>
                    <div className="animacionSpinner">
                        <ClockLoader
                            color={"#ffffff"}
                            size={60}
                            loading={this.props.show}
                            css="display: block; margin:auto;"
                        />
                        <label className="spinnerMessage"> </label>
                    </div>
                </div>
            </>
        )
    }
}