import React from "react";
import { Modal, ModalBody } from "reactstrap";
import SpinnerModal from "../../SpinnerModal/SpinnerModal";
import { AiOutlineClose } from "react-icons/ai";
import "./ModalDetalles.css";
import { GiCheckMark } from "react-icons/gi";
import { IoCloseSharp } from "react-icons/io5";
import { ToastContainer, toast } from 'react-toastify';
import { CiEdit } from "react-icons/ci";
import { FaTrashAlt } from "react-icons/fa";
import ModalMessage from "../ModalMessages/ModalMessages";
import AgregarCuenta from "../AgregarCuenta/agregarCuenta";
import { guardarCuentas } from "../../../servicios/api";


export default class ModalSubDetalles extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            loanding: false,
            verMJS: false,
            show: props.show,
            datosUser: props.infoModal,
            bandera: props.bandera,
            modalinfo: props.infoModal,
            eliminarTipoduracion: "",
            verModalCuentas: false,
            array_filtro: [],
            idcuentasMensaje: 0,
            array: [],
            idestatus: -1,
            txtuser: "",
            txtcorreo: "",
            txtapellido: "",
            txt_buscador: "",
            correoPlaceholder: "Correo",
            modalMessage: "",
            modalTitulo: "",
            banderaModal: "",
            itemBorrar: {},
            datosEditar: [],
            sortBy: "fechainicio",
            sortOrder: 'asc',
            tipobandera:"mensaje",

        }

    }

    async componentDidMount() {

        console.log(this.props.infoModal)
        this.setState({ array: JSON.parse(this.props.array) })
    }


    closemodal = (response) => {
        console.log("Leeeee  --- " + response)
        this.setState({ show: false });
        this.props.cerrarmodal(response, this.state.bandera);
    }

    ordenarLista = (key) => {
        if (this.state.sortBy === key) {
            // Si ya está ordenado por la misma clave, cambiar el orden
            this.setState((prevState) => ({
                sortOrder: prevState.sortOrder === 'asc' ? 'desc' : 'asc',
            }));
        } else {
            // Si es una nueva clave de ordenamiento, ordenar ascendentemente
            this.setState({
                sortBy: key,
                sortOrder: 'asc',
            });
        }
    }


    formatearFecha = (fecha) => {
        const [year, month, day] = fecha.split("-").map(Number);
        const date = new Date(year, month - 1, day);
        const dia = date.getDate();
        const mes = date.toLocaleString('es-ES', { month: 'short' });
        const yearFinal = date.getFullYear();
        return `${dia}/${mes}/${yearFinal}`;
    };


    seleccionarTabla = (idcuentas) => {
        const { array } = this.state;
        const newData = [];
        for (let i = 0; i < array.length; i++) {
            const item = array[i];
            if (item.idcuentas === idcuentas) {
                newData.push({ ...item, seleccionar: !item.seleccionar });
            } else {
                newData.push({ ...item, seleccionar: false });
            }
        }
        this.setState({ array: newData });
    }


    editarRegistro = (item) => {
        console.log(JSON.stringify(item))

        /*  {(this.state.verModalCuentas == true) ? (
            <AgregarCuenta show={this.state.verModalCuentas} infoModal={this.state.datosUser} bandera={this.state.banderaModal}
                cerrarmodal={this.closeEditar} />
        ) : null}  */

        this.setState({ banderaModal: "editar", datosEditar: item, verModalCuentas: !this.state.verModalCuentas, });

    }

    agregarNuevo = (pos) => {
        console.log("Arias ---- ")
        if (pos == 2) {
            this.setState({ banderaModal: "nuevo", verModalCuentas: true });
        }
        else if (pos == 1) {

            this.setState({ verModalCuentas: false });
            this.closemodal(1);
        }
        else {
            this.setState({ verModalCuentas: false });
        }
        
    }


    restaurarItem = async (dato) => {

        if (dato == 2) {
            this.setState({ banderaModal: "nuevo", verMJS: true });
        }
        else if (dato == 1) {

            this.setState({ verMJS: false });
            console.log("Preparado para eliminar ----- ")
            this.continuandoRestaurarItem(1);
        }
        else {

            this.setState({
                itemBorrar: dato,
                eliminarTipoduracion: dato.tipoduracion,
                idcuentasMensaje: dato.idcuentas,
                modalTitulo: "Restaurar cuenta",
                modalMessage: dato.titulo + ", " + this.formatearFecha(dato.fechainicio),
                tipobandera: "restaurar",
                verMJS: true
            })
        }

    }

    continuandoRestaurarItem = async () => {

        this.setState({ loanding: true })
        var infoModal = this.state.datosUser;
        var item = this.state.itemBorrar;
        var arrayTemp = [];
        arrayTemp.push({
            "idcuentas": item.idcuentas,
            "idtarea": item.idtarea,
            "idpaquete": item.idpaquete,
            "titulo": item.titulo,
            "descripcion": item.descripcion,
            "cantidad": item.cantidad,
            "idtipo": item.idtipo,
            "tipo": item.idtipo,
            "fechainicio": item.fechainicio,
            "frecuencia": item.frecuencia,
            "fechaunica": item.fechaunica,
            "repeticionesporsemana": item.repeticionesporsemana,
            "repetirdiasdelasemana": item.repetirdiasdelasemana,
            "repeticionespormes": item.repeticionespormes,
            "repetirdiasmes": item.repetirdiasmes,
            "repeticionesporano": item.repeticionesporano,
            "tipoduracion": "Siempre2",//item.tipoduracion,
            "repetirnveses": item.repetirnveses,
            "fechafin": item.fechafin,
            "pagado": item.pagado,
            "datePosted": item.datePosted,
            "cola": item.cola,
            "textobusqueda": item.textobusqueda,

            "idcat": item.idcat,
            "idsubcat": item.idsubcat, // "3a8abc32-9058-4515-9275-84cff49b8392",
            "namecat": item.namecat,
            "namesubcat": item.namesubcat,
            "descriptioncat": item.descriptioncat,
            "iconcat": item.iconcat,
            "colorcat": item.colorcat,
            "eliminado": "0"

        })



        var json1 = {
            "token": infoModal.token,
            //"iddispositivo": "123123213213",
            "cuentas": arrayTemp,
            "historial": [],
            "eliminados": []
        }

        console.log(json1)
        this.setState({ loanding: false })


        var resp = await guardarCuentas(json1)
        console.log(JSON.stringify(resp))

        if (resp.code == 0) {

            this.setState({ loanding: false })

            this.closemodal(1);
            //this.componentDidMount(1);
            toast.success("Cuenta Restaurara", { autoClose: 4500, position: toast.POSITION.TOP_LEFT });
        }
        else {
            // resp
            this.setState({ loanding: false })
        }

    }

    cerrarmodalCat = () => {
    }

    borrarArray = async (dato) => {


        if (dato == 0) {
            this.setState({ verMJS: !this.state.verMJS })
        }
        else if (dato == 1) {


            this.setState({ loanding: true, verMJS: !this.state.verMJS })
            var infoModal = this.state.datosUser;
            var json1 = {};

            if (this.state.eliminarTipoduracion == "Siempre") {
                var item = this.state.itemBorrar;
                var arrayTemp = [];
                arrayTemp.push({
                    "idcuentas": item.idcuentas,
                    "idtarea": item.idtarea,
                    "idpaquete": item.idpaquete,
                    "titulo": item.titulo,
                    "descripcion": item.descripcion,
                    "cantidad": item.cantidad,
                    "idtipo": item.idtipo,
                    "tipo": item.idtipo,
                    "fechainicio": item.fechainicio,
                    "frecuencia": item.frecuencia,
                    "fechaunica": item.fechaunica,
                    "repeticionesporsemana": item.repeticionesporsemana,
                    "repetirdiasdelasemana": item.repetirdiasdelasemana,
                    "repeticionespormes": item.repeticionespormes,
                    "repetirdiasmes": item.repetirdiasmes,
                    "repeticionesporano": item.repeticionesporano,
                    "tipoduracion": item.tipoduracion,
                    "repetirnveses": item.repetirnveses,
                    "fechafin": item.fechafin,
                    "pagado": "0",
                    "datePosted": "",
                    "cola": "",
                    "textobusqueda": item.textobusqueda,

                    "idcat": item.idcat,
                    "idsubcat": item.idsubcat, // "3a8abc32-9058-4515-9275-84cff49b8392",
                    "namecat": item.namecat,
                    "namesubcat": item.namesubcat,
                    "descriptioncat": item.descriptioncat,
                    "iconcat": item.iconcat,
                    "colorcat": item.colorcat,
                    "eliminado": "1"

                })



                json1 = {
                    "token": infoModal.token,
                    //"iddispositivo": "123123213213",
                    "cuentas": arrayTemp,
                    "historial": [],
                    "eliminados": []
                }

            }
            else {

                var arrayDelete = [];
                arrayDelete.push({ "idregistro": this.state.idcuentasMensaje, "pagina": "home" })
                json1 = {
                    "token": infoModal.token,
                    "eliminados": arrayDelete,
                    "historial": [
                    ],
                    "cuentas": [
                    ]
                }

            }


            console.log(json1)
            var resp = await guardarCuentas(json1)
            console.log(JSON.stringify(resp))

            if (resp.code == 0) {

                this.setState({ loanding: false })

                this.closemodal(1);
                //this.componentDidMount(1);
                toast.success("Cuenta eliminada", { autoClose: 4500, position: toast.POSITION.TOP_LEFT });
            }
            else {
                // resp
                this.setState({ loanding: false })
            }

        }
        else {

            console.log(dato.tipoduracion)
            this.setState({
                itemBorrar: dato,
                eliminarTipoduracion: dato.tipoduracion,
                idcuentasMensaje: dato.idcuentas,
                modalTitulo: "Eliminar cuenta",
                modalMessage: dato.titulo + ", " + this.formatearFecha(dato.fechainicio),
                tipobandera: "borrar",
                verMJS: !this.state.verMJS
            })
        }
    }


    eliminarAll = async (dato) => {

        this.setState({ loanding: true })
        var arrayDelete = [];
        var arrayDeleteTemp = []
        arrayDeleteTemp = this.state.array;

        for (let i = 0; i < arrayDeleteTemp.length; i++) {
            arrayDelete.push({ "idregistro": arrayDeleteTemp[i].idcuentas, "pagina": "home" })
        }
        var infoModal = this.state.datosUser;
        var json1 = {
            "token": infoModal.token,
            "eliminados": arrayDelete,
            "historial": [
            ],
            "cuentas": [
            ]
        }

        var resp = await guardarCuentas(json1)
        console.log(JSON.stringify(resp))

        if (resp.code == 0) {

            this.setState({ loanding: false })

            this.closemodal(1);
            //this.componentDidMount(1);
            toast.success("Cuentas eliminadas", { autoClose: 4500, position: toast.POSITION.TOP_LEFT });
        }
        else {
            // resp
            this.setState({ loanding: false })
        }


    }


    render() {

        const { array, sortBy, sortOrder } = this.state;
        const sortedData = [...array].sort((a, b) => {
            if (sortOrder === 'asc') {
                return a[sortBy] > b[sortBy] ? 1 : -1;
            } else {
                return a[sortBy] < b[sortBy] ? 1 : -1;
            }
        });


        if (!this.props.show) return null;

        return (
            <>

                <ToastContainer />

                {(this.state.loanding == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loanding} />
                ) : null}

                {(this.state.verMJS == true) ? (
                    <ModalMessage show={this.state.verMJS} titulo={this.state.modalTitulo}
                        bandera={this.state.tipobandera} mensaje={this.state.modalMessage}
                        cerrarBorrar={this.borrarArray} cerrarmodal={this.restaurarItem} />
                ) : null}

                {(this.state.verModalCuentas == true) ? (
                    <AgregarCuenta show={this.state.verModalCuentas} infoModal={this.state.modalinfo} bandera={this.state.banderaModal}
                        cerrarmodal={this.agregarNuevo} datos={this.state.datosEditar} />
                ) : null}


                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered

                    toggle={this.closemodal.bind(this, 0)} >

                    <ModalBody className="backgrounddiv" >

                        <div className="divperfilediv">
                            <div className="row" >
                                <div className="col">
                                    <label style={{ fontSize: "25px", textAlign: "center", width: "100%", color: "black" }}>Cuentas: {sortedData.length} {sortedData.length > 0 ? " - " + sortedData[0].titulo : "-----"}</label>
                                </div>
                                <div className="col-auto">
                                    <label className="textocerrarlbl" ><AiOutlineClose style={{ cursor: "pointer" }}
                                        onClick={this.closemodal.bind(this, 0)} /></label>
                                </div>
                            </div>

                            <br />
                            <button className="buttonbase messageCheck" onClick={this.eliminarAll.bind(this, 0)}>Borrar Todas</button>
                            <br />
                            <br />

                            <div className="row" style={{ backgroundColor: "#51a3ed", color: "black", paddingRight: "15px" }}>

                                <div className="col-2  sinpadding" onClick={() => this.ordenarLista('fechainicio')}>
                                    <label className="txttitlestable cursor" style={{ paddingLeft: "5px" }}>Fecha</label>
                                </div>
                                <div className="col-3  sinpadding" onClick={() => this.ordenarLista('titulo')}>
                                    <label className="txttitlestable cursor">Nombre</label>
                                </div>
                                <div className="col-2  sinpadding" onClick={() => this.ordenarLista('namesubcat')}>
                                    <label className="txttitlestable cursor">Categoría</label>
                                </div>
                                <div className="col-2 textocentro sinpadding" onClick={() => this.ordenarLista('tipo')}>
                                    <label className="txttitlestable cursor">Tipo</label>
                                </div>
                                <div className="col-1 textocentro sinpadding" onClick={() => this.ordenarLista('pagado')}>
                                    <label className="txttitlestable cursor">Pagado</label>
                                </div>
                                <div className="col-2 textocentro sinpadding" >

                                </div>

                            </div>
                            <div className='divscrollhome' style={{ maxHeight: "300px", minHeight: "100px" }} >

                                {this.state.txt_buscador.length == 0 ?
                                    <>
                                        {sortedData.map((item, index) => (

                                            <div key={index} className={`row ${item.seleccionar ? 'nuevocolorfondo' : index % 2 === 0 ? 'eve' : 'low'}`}
                                                onClick={this.seleccionarTabla.bind(this, item.idcuentas)}>

                                                <div className="col-2 spaciostabla ">
                                                    <label className="subtexttableRecordatorio" style={{ paddingLeft: "5px" }}>{this.formatearFecha(item.fechainicio)}</label>
                                                </div>

                                                <div className="col-3 spaciostabla sinpadding">
                                                    <label className="subtexttableRecordatorio">{item.titulo != "" ? item.titulo : "-----"}</label>
                                                </div>

                                                <div className="col-2 spaciostabla sinpadding">
                                                    <label className="subtexttableRecordatorio">{item.namesubcat}</label>
                                                </div>

                                                <div className="col-2 spaciostabla textocentro sinpadding">
                                                    <label className="subtexttableRecordatorio">{item.tipo}</label>
                                                </div>

                                                <div className="col-1 spaciostabla textocentro sinpadding">
                                                    <label className="subtexttableRecordatorio">
                                                        {item.pagado === "0" && item.eliminado === "0" ?
                                                            <IoCloseSharp style={{ fontSize: "medium", color: "red" }} />

                                                            : item.pagado === "0" && item.eliminado === "1" ?
                                                                <spam>Eliminado</spam>

                                                                : item.pagado === "1" && item.eliminado === "0" ?
                                                                    <GiCheckMark style={{ fontSize: "medium", color: "green" }} />

                                                                    : item.pagado === "1" && item.eliminado === "1" ?
                                                                        <spam>Eliminado</spam>



                                                                        : null}
                                                    </label>
                                                </div>
                                                <div className="col-2 spaciostabla textocentro sinpadding ">
                                                    {item.pagado === "0" && item.eliminado === "0" ?
                                                        <>
                                                            <CiEdit className="icoedit" style={{ color: item.seleccionar ? "white" : "" }} onClick={this.editarRegistro.bind(this, item)} />
                                                            <FaTrashAlt className="icodelate" onClick={this.borrarArray.bind(this, item, 0)} />
                                                        </>

                                                        : item.pagado === "0" && item.eliminado === "1" ?
                                                            <button className="buttonbase btnbtnnerw" onClick={this.restaurarItem.bind(this, item)}>Restaurar</button>


                                                            : item.pagado === "1" && item.eliminado === "0" ?
                                                                null

                                                                : item.pagado === "1" && item.eliminado === "1" ?
                                                                    null



                                                                    : null}




                                                    {/*item.pagado == "0" ?
                                                    
                                                        : null
                                                    */}
                                                </div>

                                            </div>
                                        ))
                                        }
                                    </>
                                    :
                                    <>
                                        {this.state.array_filtro.map((item, index) => (

                                            <div key={index} className={`row ${item.seleccionar ? 'nuevocolorfondo' : index % 2 === 0 ? 'eve' : 'low'}`}
                                                onClick={this.seleccionarTabla.bind(this, item.idcuentas)}>

                                                <div className="col-2  ">
                                                    <label className="subtexttableRecordatorio" style={{ paddingLeft: "5px" }}>{this.formatearFecha(item.fechainicio)}</label>
                                                </div>

                                                <div className="col-3  sinpadding">
                                                    <label className="subtexttableRecordatorio">{item.titulo != "" ? item.titulo : "-----"}</label>
                                                </div>

                                                <div className="col-3  sinpadding">
                                                    <label className="subtexttableRecordatorio">{item.namesubcat}</label>
                                                </div>

                                                <div className="col-2 textocentro sinpadding">
                                                    <label className="subtexttableRecordatorio">{item.tipo}</label>
                                                </div>

                                                <div className="col-1 textocentro sinpadding">
                                                    <label className="subtexttableRecordatorio">
                                                        {item.pagado == "0" ?
                                                            <IoCloseSharp style={{ fontSize: "medium", color: "red" }} />
                                                            : <GiCheckMark style={{ fontSize: "medium", color: "green" }} />}
                                                    </label>
                                                </div>
                                                <div className="col-2 spaciostabla textocentro sinpadding ">
                                                    <CiEdit className="icoedit" style={{ color: item.seleccionar ? "white" : "" }} onClick={this.cerrarmodalCat.bind(this, 1, item)} />
                                                    <FaTrashAlt className="icodelate" onClick={this.borrarArray.bind(this, item, 1)} />
                                                </div>

                                            </div>

                                        ))}
                                    </>
                                }

                            </div>
                            <br />
                            <div className="modalmessage-footer">
                                <button className="buttonbase message-alert-ok" onClick={this.closemodal.bind(this, 0)}>Cerrar</button>
                            </div>
                        </div>

                    </ModalBody>
                </Modal>
            </>
        )

    }

}
