
import React from "react";
import { FaUserTie } from "react-icons/fa";

import NavBar from "../../componentes/navbar/navbar";
import "./../Login/Login.css";
import "./Perfil.css"
import { Input } from "reactstrap";
import { MdAlternateEmail } from "react-icons/md";

import { Button } from "@mui/material";
import { Redirect } from "react-router-dom";
import { actualizarUser } from "../../servicios/api";
import { ToastContainer, toast } from 'react-toastify';
import SpinnerModal from "../../componentes/SpinnerModal/SpinnerModal";
import ChangePassword from "../../componentes/Modals/ChangePassword/ChangePassword";
import { LogInCall } from "../../servicios/StoreCall";


export default class Perfil extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            ventana: 'home',
            txtnombre: "",
            txtcorreo: "",
            txtapellido: "",
            continuar: false,
            salir: false,
            loanding: false,
            editor: false,
            bloquear: true,
            avanzar: false,
            verpass: false,
        }


    }

    componentDidMount() {
         this.setState({ loanding: true })
        var datos = localStorage.getItem("datos");
        let array = datos.split(",");
        this.setState({ txtnombre: array[1] });
        this.setState({ txtapellido: array[2] });
        this.setState({ txtcorreo: array[3] });

         this.setState({ loanding: false })

    }


    habilitartxt = async () => {




        if (this.state.bloquear == true) {
            this.setState({ bloquear: !this.state.bloquear })
        }
        else {

            if (this.state.txtnombre.trim().length > 0) {

                if (this.validateEmail(this.state.txtcorreo)) {


                    await this.setState({ loanding: true })

                    let json = {
                        "nombre": this.state.txtnombre.trim(),
                        "apellido": this.state.txtapellido.trim(),
                        "email": this.state.txtcorreo.trim()
                    }

                    console.log(JSON.stringify(json))
                    var resp = await actualizarUser(json)
                    if (resp.code == 0) {

                        this.setState({ bloquear: !this.state.bloquear })
                        toast.success("Informacón actualizada", { autoClose: 2500, position: toast.POSITION.TOP_LEFT });

                        var datos = localStorage.getItem("datos");
                        let array = datos.split(",");
                        this.setState({ txtnombre: array[1] });

                        LogInCall(array[0] + "," + this.state.txtnombre.trim() + "," + this.state.txtapellido.trim() + "," + this.state.txtcorreo.trim());
                        this.componentDidMount()
                       

                    }
                    else {
                        this.setState({ loanding: false })
                        toast.error(resp.message, { autoClose: 3000, position: toast.POSITION.TOP_LEFT });
                        return;
                    }


                } else {
                    toast.warn("Correo inválido", { autoClose: 4000, position: toast.POSITION.TOP_LEFT });
                    return;
                }


            } else {
                toast.warn("Escribe un nombre", { autoClose: 4000, position: toast.POSITION.TOP_LEFT });
                return;
            }
        }

    }


    validateEmail = (email) => {
        // Expresión regular para validar el correo electrónico
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return regex.test(email);
    }



    handlePasswordChange = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    };


    nextPro = () => {

        this.setState({ avanzar: !this.state.avanzar })

    }

    closemodal = (dato) => {
        this.setState({ verpass: !this.state.verpass })
        if (dato == 1) {

            this.componentDidMount();

        }
    }

    render() {
        return (

            <>

                <ToastContainer />
                {(this.state.avanzar == true) ? (
                    <Redirect from='/panel' to={"/perfil"} />
                ) : null}

                {(this.state.loanding == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loanding} />
                ) : null}

                {(this.state.verpass == true) ? (
                    <ChangePassword show={this.state.verpass} cerrarmodal={this.closemodal} />
                ) : null}
                
                {(this.state.loanding == false) ? (
                    <NavBar ventana="perfil" />
                ) : null}


                <div className="background">
                    <div className="divperfile">
                        <div className="user-profile" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <FaUserTie padding className='profile-image' />
                            <br />
                            <p className="lblcambiarpass" onClick={this.closemodal.bind(this.closemodal, 0)}>Cambiar contraseña</p>

                            <br />


                            <div className="profile-details" style={{ textAlign: 'center', marginTop: "10px" }}>
                                <div style={{ position: "relative", width: "100%" }}>

                                    <FaUserTie style={{ color: "#578cff", position: "absolute", fontSize: "large", top: "10px", left: "10px", zIndex: 10 }} />
                                    <Input disabled={this.state.bloquear} placeholder="Nombre" className="inputprofile" type={"text"} style={{ paddingLeft: "35px" }}
                                        onChange={this.handlePasswordChange("txtnombre")} value={this.state.txtnombre} />
                                </div>


                                <br />
                                <div style={{ position: "relative", width: "100%" }}>

                                    <FaUserTie style={{ color: "#578cff", position: "absolute", fontSize: "large", top: "10px", left: "10px", zIndex: 10 }} />
                                    <Input disabled={this.state.bloquear} placeholder="Apellido" className="inputprofile" type={"text"} style={{ paddingLeft: "35px" }}
                                        onChange={this.handlePasswordChange("txtapellido")} value={this.state.txtapellido} />

                                </div>
                                <br />

                                <div style={{ position: "relative", width: "100%" }}>

                                    <MdAlternateEmail style={{ color: "#578cff", position: "absolute", fontSize: "large", top: "10px", left: "10px", zIndex: 10 }} />
                                    <Input disabled={this.state.bloquear} placeholder="example@email.com" className="inputprofile" type={"text"} style={{ paddingLeft: "35px" }}
                                        onChange={this.handlePasswordChange("txtcorreo")} value={this.state.txtcorreo} />

                                </div>





                                <Button
                                    onClick={this.habilitartxt}
                                    variant="contained"
                                    fullWidth
                                    style={{
                                        marginTop: "25px",
                                        textTransform: "capitalize",
                                        borderRadius: "30px",
                                        padding: "5px",
                                        fontSize: "medium",
                                        backgroundColor: this.state.bloquear == false ? "#4137a4" : "#0066c0",

                                        color: "white"
                                    }}

                                >
                                    {this.state.bloquear == false ? "Guardar" : "Modificar"}
                                </Button>

                            </div>
                        </div>
                    </div>
                </div>



            </>
        )
    }
} 