import React from "react";
import { Modal, ModalBody, Input } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import { Button } from "@mui/material";
import SpinnerModal from "../../SpinnerModal/SpinnerModal";
import "./agregarCuenta.css"
import { AiOutlineClose } from "react-icons/ai";
import { guardarCuentas } from "../../../servicios/api";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ModalCategory from "./modalCategory";
import ModalFracuencia from "./modalFrecuencia";



export default class AgregarCuenta extends React.Component {

    constructor(props) {
        super(props)
        //console.log(this.props);
        this.state = {
            loanding: false,
            show: this.props.show,
            datosCuentas: [],
            bandera: props.bandera,
            infoModal: props.infoModal,
            idcuentasGeneral: "0",
            idtareaGeneral: "0",
            eliminadoGeneral: "0",

            tituloModal: "Crear Egreso",
            idestatus: -1,
            idtipo: "expense",
            txttitulo: "",
            txtcantidad: "",
            array_frecuencia: { "tipo": "unica", "cantidaD": 1, "tipoduracion": "Repetirnveses", "hasta": "", "nveces": 10 },
            tituloG: "Única ocasión",
            fechaSeleccionada: new Date(),
            txtdescripcion: "",
            arrayCategoria: { "id": 74, "idcategoria": "3a8abc32-9058-4515-9275-84cff49b8392", "name": "Otros (gastos varios)", "description": "", "icon": "icon_common_services", "type": "expense", "color": "A50000", "parent": "de6f5763-7277-476b-a197-02e1951d4dad", "collapse": false, "seleccionar": true },
            arrayCategoriaPadre: {
                "id": 45, "idcategoria": "de6f5763-7277-476b-a197-02e1951d4dad",
                "name": "Gastos Varios", "description": "",
                "icon": "icon_severa_expenses", "type": "expense",
                "color": "A50000", "parent": "", "collapse": false
            },
            vercategorias: false,
            idPaquetet: Math.floor(Date.now() / 1000).toString()

        }

    }

    async componentDidMount() {


        console.log("---- ARias - \n" + JSON.stringify(this.state.infoModal))
        if (this.props.bandera == "editar") {

            /*
            const arrayD = {
                "idcuentas": 57158,
                "timestamp": 1727854298406,
                "idtarea": 216,
                "idpaquete": "1727854292",
                "titulo": "-Sin Título- ok",
                "descripcion": "",
                "cantidad": "44.00",
                "idtipo": "expense",
                "tipo": "expense",
                "fechainicio": "2024-10-04",
                "frecuencia": "unique",
                "fechaunica": "2024-10-01",
                "repeticionesporsemana": "",
                "repetirdiasdelasemana": "",
                "repeticionespormes": "",
                "repetirdiasmes": "",
                "repeticionesporano": "",
                "tipoduracion": "Siempre",
                "repetirnveses": "10",
                "fechafin": "2025-01-05",
                "pagado": "0",
                "datePosted": "2024-10-04",
                "cola": "",
                "textobusqueda": "-sin titulo- ok  otros (gastos varios) otros (gastos varios) ",
                "idcat": "3a8abc32-9058-4515-9275-84cff49b8392",
                "idsubcat": "3a8abc32-9058-4515-9275-84cff49b8392",
                "namecat": "Otros (Gastos varios)",
                "namesubcat": "Otros (Gastos varios)",
                "descriptioncat": "",
                "iconcat": "icon_common_services",
                "colorcat": "A50000",
                "fecha": "2024-10-02 01:31:38",
                "seleccionar": false,
                "total": 0
            }/ */
            const arrayD = this.props.datos;
            console.log(JSON.stringify(arrayD));

            let nuevaFecha;

            if (arrayD.tipoduracion === "unique") {
                nuevaFecha = this.convertirFechaLocal(arrayD.fechaunica);
            } else {
                nuevaFecha = this.convertirFechaLocal(arrayD.fechainicio);
            }



            const title = arrayD.tipo === "expense" ? "Editar Egreso" : "Editar Ingreso"
            let arrayCategoriaPadreT = {
                "id": arrayD.idcat, "idcategoria": arrayD.idsubcat,
                "name": arrayD.namecat, "description": arrayD.descripcion,
                "icon": arrayD.iconcat, "type": arrayD.idtipo,
                "color": arrayD.colorcat, "parent": arrayD.idcat, "collapse": false
            }

            let arrayCategoriaT = {
                "id": arrayD.idcat,
                "idcategoria": arrayD.idsubcat,
                "name": arrayD.namesubcat,
                "description": arrayD.descripcion,
                "icon": arrayD.iconcat,
                "type": arrayD.idtipo,
                "color": arrayD.colorcat,
                "parent": arrayD.idcat,
                "collapse": false,
                "seleccionar": true
            }


            let tdias = 1;
            if (arrayD.frecuencia == "daily") {

            }
            else if (arrayD.frecuencia == "weekly") {
                tdias = arrayD.repeticionesporsemana;
            }
            else if (arrayD.frecuencia == "monthly") {
                tdias = arrayD.repeticionespormes;
            }
            else if (arrayD.frecuencia == "yearly") {
                tdias = arrayD.repeticionesporano;
            }

            let arrarFrecuencia = {
                "tipo": arrayD.frecuencia,
                "cantidaD": tdias,
                "tipoduracion": arrayD.tipoduracion,
                "hasta": arrayD.fechafin,
                "nveces": arrayD.repetirnveses
            }

            //console.log(JSON.stringify(arrarFrecuencia))
            this.setState({
                eliminadoGeneral: arrayD.eliminado,
                idtareaGeneral: arrayD.idtarea,
                tituloModal: title,
                idPaquetet: arrayD.idpaquete,
                txttitulo: arrayD.titulo,
                txtcantidad: arrayD.cantidad,
                idtipo: arrayD.tipo,
                fechaSeleccionada: nuevaFecha,
                txtdescripcion: arrayD.descripcion,
                arrayCategoria: arrayCategoriaT,
                array_frecuencia: arrarFrecuencia,
                idcuentasGeneral: arrayD.idcuentas,

                /*  array_frecuencia: {
                            "tipo": arrayD.tipo,
                            "cantidaD": 0,
                            "tipoduracion": "unique",
                            "hasta": "", "nveces": 1
                    }
                */

                tituloG: "Única ocasiónTest",
            })
        }

    }

    manejarCambioFecha = (fecha) => {
        this.setState({ fechaSeleccionada: fecha });
    };

    convertirFechaLocal = (fechaString) => {
        const [year, month, day] = fechaString.split("-").map(Number);
        return new Date(year, month - 1, day); // Mes basado en 0
    }
    closemodal = (dato) => {

        this.setState({ show: false });
        this.props.cerrarmodal(dato);
    }

    format_moneda = (number) => {
        return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD', minimumFractionDigits: 2 }).format(number);
    }

    guardatos = async () => {


        console.log("----------")
        console.log(this.state.idPaquetet)

        if (this.state.txttitulo.trim() === "") {
            toast.warn("Titulo obligatorio", { autoClose: 4000, position: toast.POSITION.TOP_LEFT });
            return;
        }
        this.setState({ loanding: true })

        var fechainicio_formato = this.formatoFecha(this.state.fechaSeleccionada);
        var fechafin_formato = "";
        if (this.state.array_frecuencia.hasta != "") {
            fechafin_formato = this.formatoFecha(this.state.array_frecuencia.hasta);
        }

        const res = this.state.txtcantidad.trim()
        var limpiarCantidad = res.replace(/,/g, "");


        const textoLimpio = this.state.txttitulo.trim() + " " + this.state.txtdescripcion.trim() + " " + this.state.arrayCategoria.description
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase();

        var arrayTemp = [];
        console.log(this.state.array_frecuencia.tipo + "-------")
        if (this.state.array_frecuencia.tipo == "unique" || this.state.array_frecuencia.tipo == "unica") { // unique

            arrayTemp.push({
                "idcuentas": this.state.idcuentasGeneral,
                "idtarea": this.state.idtareaGeneral,
                "idpaquete": this.state.idPaquetet,
                "titulo": this.state.txttitulo.trim(),
                "descripcion": this.state.txtdescripcion.trim(),
                "cantidad": limpiarCantidad,
                "idtipo": this.state.idtipo,
                "tipo": this.state.idtipo,
                "fechainicio": fechainicio_formato,
                "frecuencia": "unique",
                "fechaunica": fechainicio_formato,
                "repeticionesporsemana": this.state.array_frecuencia.cantidaD,
                "repetirdiasdelasemana": this.state.array_frecuencia.cantidaD,
                "repeticionespormes": this.state.array_frecuencia.cantidaD,
                "repetirdiasmes": this.state.array_frecuencia.cantidaD,
                "repeticionesporano": this.state.array_frecuencia.cantidaD,
                "tipoduracion": this.state.array_frecuencia.tipoduracion,
                "repetirnveses": this.state.array_frecuencia.nveces,
                "fechafin": fechafin_formato,
                "pagado": "0",
                "datePosted": "",
                "cola": "",
                "textobusqueda": textoLimpio.trim(),

                "idcat": this.state.arrayCategoriaPadre.id,
                "idsubcat": this.state.arrayCategoria.id, // "3a8abc32-9058-4515-9275-84cff49b8392",
                "namecat": this.state.arrayCategoriaPadre.name, // "Otros (Gastos varios)",
                "namesubcat": this.state.arrayCategoria.name, // "Otros (Gastos varios)",
                "descriptioncat": this.state.arrayCategoria.description,
                "iconcat": this.state.arrayCategoria.icon,
                "colorcat": this.state.arrayCategoria.color,
                "eliminado": this.state.eliminadoGeneral

            })

        }
        else {// daily, weekly, monthly, yearly

            console.log(this.state.array_frecuencia.tipoduracion + " ***** ")


            if (this.props.bandera == "editar") {

console.log("bandera editar --- ")
                arrayTemp = [];
                arrayTemp.push({
                    "idcuentas": this.state.idcuentasGeneral,
                    "idtarea": this.state.idtareaGeneral,
                    "idpaquete": this.state.idPaquetet,
                    "titulo": this.state.txttitulo.trim(),
                    "descripcion": this.state.txtdescripcion.trim(),
                    "cantidad": limpiarCantidad,
                    "idtipo": this.state.idtipo,
                    "tipo": this.state.idtipo,
                    "fechainicio": fechainicio_formato,
                    "frecuencia": this.state.array_frecuencia.tipoduracion,
                    "fechaunica": fechainicio_formato,
                    "repeticionesporsemana": this.state.array_frecuencia.cantidaD,
                    "repetirdiasdelasemana": this.state.array_frecuencia.cantidaD,
                    "repeticionespormes": this.state.array_frecuencia.cantidaD,
                    "repetirdiasmes": this.state.array_frecuencia.cantidaD,
                    "repeticionesporano": this.state.array_frecuencia.cantidaD,
                    "tipoduracion": this.state.array_frecuencia.tipoduracion,
                    "repetirnveses": this.state.array_frecuencia.nveces,
                    "fechafin": fechafin_formato,
                    "pagado": "0",
                    "datePosted": "",
                    "cola": "",
                    "textobusqueda": textoLimpio.trim(),

                    "idcat": this.state.arrayCategoriaPadre.id,
                    "idsubcat": this.state.arrayCategoria.id, // "3a8abc32-9058-4515-9275-84cff49b8392",
                    "namecat": this.state.arrayCategoriaPadre.name, // "Otros (Gastos varios)",
                    "namesubcat": this.state.arrayCategoria.name, // "Otros (Gastos varios)",
                    "descriptioncat": this.state.arrayCategoria.description,
                    "iconcat": this.state.arrayCategoria.icon,
                    "colorcat": this.state.arrayCategoria.color,
                    "eliminado": this.state.eliminadoGeneral

                })

                //

    /*
                var json = {
                    "token": this.state.infoModal.token,
                    //"iddispositivo": "123123213213",
                    "cuentas": arrayTemp,
                    "historial": [],
                    "eliminados": []
                }
                console.log(json)
            var resp = await guardarCuentas(json)
                console.log(JSON.stringify(resp))
        
                if (resp.code == 0) {
                    this.closemodal(1);
                    this.setState({ loanding: false })
                }
                else {
                    // resp
                    this.setState({ loanding: false })
                }
                */


            } else {

                console.log("bandera nueva --- ")
                if (this.state.array_frecuencia.tipoduracion == "Repetirnveses") {

                    const startDate = fechainicio_formato;
                    const frequency = { type: this.state.array_frecuencia.tipo, value: this.state.array_frecuencia.cantidaD };
                    const occurrences = this.state.array_frecuencia.nveces;
                    const endDate = null;//."2025-05-29"; // Fecha límite

                    const respi = this.calculateDates(startDate, frequency, occurrences, endDate);
                    console.log("----------");
                    console.log(respi);
                    console.log("----------");

                    arrayTemp = [];
                    for (let i = 0; i < respi.length; i++) {
                        arrayTemp.push({
                            "idcuentas": this.state.idcuentasGeneral,
                            "idtarea": this.state.idtareaGeneral,
                            "idpaquete": this.state.idPaquetet,
                            "titulo": this.state.txttitulo.trim(),
                            "descripcion": this.state.txtdescripcion.trim(),
                            "cantidad": limpiarCantidad,
                            "idtipo": this.state.idtipo,
                            "tipo": this.state.idtipo,
                            "fechainicio": respi[i],
                            "frecuencia": this.state.array_frecuencia.tipo,
                            "fechaunica": respi[i],
                            "repeticionesporsemana": this.state.array_frecuencia.cantidaD,
                            "repetirdiasdelasemana": this.state.array_frecuencia.cantidaD,
                            "repeticionespormes": this.state.array_frecuencia.cantidaD,
                            "repetirdiasmes": this.state.array_frecuencia.cantidaD,
                            "repeticionesporano": this.state.array_frecuencia.cantidaD,
                            "tipoduracion": this.state.array_frecuencia.tipoduracion,
                            "repetirnveses": this.state.array_frecuencia.nveces,
                            "fechafin": fechafin_formato,
                            "pagado": "0",
                            "datePosted": "",
                            "cola": "",
                            "textobusqueda": textoLimpio.trim(),

                            "idcat": this.state.arrayCategoriaPadre.id,
                            "idsubcat": this.state.arrayCategoria.id, // "3a8abc32-9058-4515-9275-84cff49b8392",
                            "namecat": this.state.arrayCategoriaPadre.name, // "Otros (Gastos varios)",
                            "namesubcat": this.state.arrayCategoria.name, // "Otros (Gastos varios)",
                            "descriptioncat": this.state.arrayCategoria.description,
                            "iconcat": this.state.arrayCategoria.icon,
                            "colorcat": this.state.arrayCategoria.color,
                            "eliminado": this.state.eliminadoGeneral

                        })
                    }

                }
                else if (this.state.array_frecuencia.tipoduracion == "Hasta") {

                    //type daily, weekly, monthly, yearly
                    const startDate = fechainicio_formato;
                    const frequency = { type: this.state.array_frecuencia.tipo, value: this.state.array_frecuencia.cantidaD };
                    const occurrences = null;
                    const endDate = fechafin_formato;

                    const respi = this.calculateDates(startDate, frequency, occurrences, endDate);
                    arrayTemp = [];
                    for (let i = 0; i < respi.length; i++) {
                        arrayTemp.push({
                            "idcuentas": this.state.idcuentasGeneral,
                            "idtarea": this.state.idtareaGeneral,
                            "idpaquete": this.state.idPaquetet,
                            "titulo": this.state.txttitulo.trim(),
                            "descripcion": this.state.txtdescripcion.trim(),
                            "cantidad": limpiarCantidad,
                            "idtipo": this.state.idtipo,
                            "tipo": this.state.idtipo,
                            "fechainicio": respi[i],
                            "frecuencia": this.state.array_frecuencia.tipo,
                            "fechaunica": respi[i],
                            "repeticionesporsemana": this.state.array_frecuencia.cantidaD,
                            "repetirdiasdelasemana": this.state.array_frecuencia.cantidaD,
                            "repeticionespormes": this.state.array_frecuencia.cantidaD,
                            "repetirdiasmes": this.state.array_frecuencia.cantidaD,
                            "repeticionesporano": this.state.array_frecuencia.cantidaD,
                            "tipoduracion": this.state.array_frecuencia.tipoduracion,
                            "repetirnveses": this.state.array_frecuencia.nveces,
                            "fechafin": fechafin_formato,
                            "pagado": "0",
                            "datePosted": "",
                            "cola": "",
                            "textobusqueda": textoLimpio.trim(),

                            "idcat": this.state.arrayCategoriaPadre.id,
                            "idsubcat": this.state.arrayCategoria.id, // "3a8abc32-9058-4515-9275-84cff49b8392",
                            "namecat": this.state.arrayCategoriaPadre.name, // "Otros (Gastos varios)",
                            "namesubcat": this.state.arrayCategoria.name, // "Otros (Gastos varios)",
                            "descriptioncat": this.state.arrayCategoria.description,
                            "iconcat": this.state.arrayCategoria.icon,
                            "colorcat": this.state.arrayCategoria.color,
                            "eliminado": this.state.eliminadoGeneral
                        })
                    }
                }
                else if (this.state.array_frecuencia.tipoduracion == "Siempre" || this.state.array_frecuencia.tipoduracion == "Siempre2") {

                    arrayTemp = [];
                    arrayTemp.push({
                        "idcuentas": this.state.idcuentasGeneral,
                        "idtarea": this.state.idtareaGeneral,
                        "idpaquete": this.state.idPaquetet,
                        "titulo": this.state.txttitulo.trim(),
                        "descripcion": this.state.txtdescripcion.trim(),
                        "cantidad": limpiarCantidad,
                        "idtipo": this.state.idtipo,
                        "tipo": this.state.idtipo,
                        "fechainicio": fechainicio_formato,
                        "frecuencia": this.state.array_frecuencia.tipoduracion,
                        "fechaunica": fechainicio_formato,
                        "repeticionesporsemana": this.state.array_frecuencia.cantidaD,
                        "repetirdiasdelasemana": this.state.array_frecuencia.cantidaD,
                        "repeticionespormes": this.state.array_frecuencia.cantidaD,
                        "repetirdiasmes": this.state.array_frecuencia.cantidaD,
                        "repeticionesporano": this.state.array_frecuencia.cantidaD,
                        "tipoduracion": "Siempre2", //this.state.array_frecuencia.tipoduracion,
                        "repetirnveses": this.state.array_frecuencia.nveces,
                        "fechafin": fechafin_formato,
                        "pagado": "0",
                        "datePosted": "",
                        "cola": "",
                        "textobusqueda": textoLimpio.trim(),

                        "idcat": this.state.arrayCategoriaPadre.id,
                        "idsubcat": this.state.arrayCategoria.id, // "3a8abc32-9058-4515-9275-84cff49b8392",
                        "namecat": this.state.arrayCategoriaPadre.name, // "Otros (Gastos varios)",
                        "namesubcat": this.state.arrayCategoria.name, // "Otros (Gastos varios)",
                        "descriptioncat": this.state.arrayCategoria.description,
                        "iconcat": this.state.arrayCategoria.icon,
                        "colorcat": this.state.arrayCategoria.color,
                        "eliminado": this.state.eliminadoGeneral

                    })
                }

            }

        }

        var json = {
            "token": this.state.infoModal.token,
            //"iddispositivo": "123123213213",
            "cuentas": arrayTemp,
            "historial": [],
            "eliminados": []
        }
        console.log(" ------------- ")
        console.log(json)
      var resp = await guardarCuentas(json)
        console.log(JSON.stringify(resp))

        if (resp.code == 0) {

            if (this.props.bandera == "editar") {
                toast.success("Cuenta actualizada", { autoClose: 4500, position: toast.POSITION.TOP_LEFT });
            }
            else {
                toast.success("Cuentas creada con éxito", { autoClose: 4500, position: toast.POSITION.TOP_LEFT });
            }

            this.closemodal(1);
            this.setState({ loanding: false })
        }
        else {
            // resp
            this.setState({ loanding: false })
        }

    }


    calculateDates = (startDate, frequency, occurrences = null, endDate = null) => {
        var dates = [];
        let currentDate = new Date(`${startDate}T00:00:00`);
        while (true) {
            const formattedDate = this.formatoFecha(currentDate);

            // Si la fecha es mayor que la fecha final, salimos
            if (endDate && formattedDate > endDate) {
                break;
            }
            // Si hemos generado suficientes fechas, salimos
            if (occurrences && dates.length >= occurrences) {
                break;
            }
            // Agregar la fecha actual a la lista
            dates.push(formattedDate);
            // Avanzar a la siguiente fecha según la frecuencia
            switch (frequency.type) {
                case 'daily':
                    currentDate.setDate(currentDate.getDate() + frequency.value);
                    break;
                case 'weekly':
                    currentDate.setDate(currentDate.getDate() + 7 * frequency.value);
                    break;
                case 'monthly':
                    currentDate.setMonth(currentDate.getMonth() + frequency.value);
                    break;
                case 'yearly':
                    currentDate.setFullYear(currentDate.getFullYear() + frequency.value);
                    break;
                default:
                    throw new Error('Frecuencia desconocida');
            }
        }

        return dates;
    };


    manejarCambio = (e) => {
        const valorSinFormato = e.target.value.replace(/,/g, "");
        if (!isNaN(valorSinFormato)) {
            const cantidadFormateada = this.formatearCantidad(valorSinFormato);
            this.setState({ txtcantidad: cantidadFormateada });
        }
    };

    formatearCantidad = (valor) => {
        const partes = valor.split(".");
        partes[0] = parseInt(partes[0] || 0, 10).toLocaleString("en-US");
        return partes.join(".");
    };

    escribirtxt = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    }

    btntipo = (ban) => {
        if (ban == 1) {
            this.setState({ idtipo: "expense", tituloModal: "Crear Egreso" });
        }
        else {
            this.setState({ idtipo: "income", tituloModal: "Crear Ingreso" });
        }
    }
    openfrecuencia = (dato, item) => {


        if (dato == 1) {
            this.setState({ verFrecuencia: !this.state.verFrecuencia })
            // this.setState({ arrayCategoria: item })
            console.log(JSON.parse(JSON.stringify(item)));
            this.setState({ array_frecuencia: item });


            /*
                      if(arrayD.frecuencia == "daily") {
                        
                        }
                        else if(arrayD.frecuencia == "weekly") {
                            tdias = arrayD.repeticionesporsemana;
                        }
                        else if(arrayD.frecuencia == "monthly") {
                            tdias = arrayD.repeticionespormes; 
                        }
                        else if(arrayD.frecuencia == "yearly") {
                            tdias = arrayD.repeticionesporano;
                        }
            */



            if (item.tipo == "unique") {
                this.setState({ tituloG: "Única ocasión" });
            }
            else if (item.tipo == "daily") {
                var a = '', b = '';
                if (item.cantidaD <= 1) {
                    a = "Todos los días";
                }
                else {
                    a = "Cada " + item.cantidaD + " días";
                }
                if (item.tipoduracion == "Siempre") {
                    b = ", Siempre";
                }
                else if (item.tipoduracion == "Repetirnveses") {
                    b = ", Repetir(" + item.nveces + ")";
                }
                else if (item.tipoduracion == "Hasta") {
                    b = ", Hasta(" + this.formatearFecha(item.hasta) + ")";
                }
                this.setState({ tituloG: a + "" + b })


            }
            else if (item.tipo == "weekly") {
                var a = '', b = '';
                if (item.cantidaD <= 1) {
                    a = "Todas las semanas";
                }
                else {
                    a = "Cada " + item.cantidaD + " semanas";
                }
                if (item.tipoduracion == "Siempre") {
                    b = ", Siempre";
                }
                else if (item.tipoduracion == "Repetirnveses") {
                    b = ", Repetir(" + item.nveces + ")";
                }
                else if (item.tipoduracion == "Hasta") {
                    b = ", Hasta(" + this.formatearFecha(item.hasta) + ")";
                }
                this.setState({ tituloG: a + "" + b });
            }

            else if (item.tipo == "monthly") {
                var a = '', b = '';
                if (item.cantidaD <= 1) {
                    a = "Todos los meses";
                }
                else {
                    a = "Cada " + item.cantidaD + " mes";
                }

                if (item.tipoduracion == "Siempre") {
                    b = ", Siempre";
                }
                else if (item.tipoduracion == "Repetirnveses") {
                    b = ", Repetir(" + item.nveces + ")";
                }
                else if (item.tipoduracion == "Hasta") {
                    b = ", Hasta(" + this.formatearFecha(item.hasta) + ")";
                }
                this.setState({ tituloG: a + "" + b });
            }

            else if (item.tipo == "yearly") {
                var a = '', b = '';
                if (item.cantidaD <= 1) {
                    a = "Todos los años";
                }
                else {
                    a = "Cada " + item.cantidaD + " año";
                }

                if (item.tipoduracion == "Siempre") {
                    b = ", Siempre";
                }
                else if (item.tipoduracion == "Repetirnveses") {
                    b = ", Repetir(" + item.nveces + ")";
                }
                else if (item.tipoduracion == "Hasta") {
                    b = ", Hasta(" + this.formatearFecha(item.hasta) + ")";
                }
                this.setState({ tituloG: a + "" + b });

            }

        }
        else {
            this.setState({ verFrecuencia: !this.state.verFrecuencia })
        }
    }

    formatearFecha = (fecha) => {
        const [year, month, day] = fecha.split("-").map(Number);
        const date = new Date(year, month - 1, day);
        const dia = date.getDate();
        const mes = date.toLocaleString('es-ES', { month: 'short' });
        const yearFinal = date.getFullYear();
        return `${dia}/${mes}/${yearFinal}`;
    };

    opencategori = (dato, item) => {


        if (dato == 1) {

            this.setState({ arrayCategoria: item.subCat })
            this.setState({ arrayCategoriaPadre: item.cat })
            console.log(JSON.stringify(item))
            this.setState({ vercategorias: !this.state.vercategorias })
        }
        else {
            this.setState({ vercategorias: !this.state.vercategorias })
        }
    }

    formatoFecha = (isoDateString) => {
        const date = new Date(isoDateString);
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Mes empieza en 0
        const day = String(date.getDate()).padStart(2, '0'); // Día del mes
        return `${year}-${month}-${day}`;
    };

    render() {

        return (

            <>

                <ToastContainer />

                {(this.state.loanding == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loanding} />
                ) : null}

                {(this.state.vercategorias == true) ? (
                    <ModalCategory tipo="full" show={this.state.vercategorias} cerrarmodal={this.opencategori} />
                ) : null}

                {(this.state.verFrecuencia == true) ? (
                    <ModalFracuencia tipo="full" show={this.state.verFrecuencia} cerrarmodal={this.openfrecuencia}
                        datos={JSON.stringify(this.state.array_frecuencia)} />
                ) : null}


                <Modal isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered
                    style={{ width: "380px" }}

                    toggle={this.closemodal.bind(this, 0)} >

                    <ModalBody className="backgrounddiv" >



                        <div className="divperfilediv">


                            <div className="row" >
                                <div className="col-auto ">
                                    <label className="lbltitlesModal" style={{ fontSize: "large" }}>{this.props.bandera == "editar" ?
                                        "Actualizar cuenta" : this.state.tituloModal}
                                    </label>
                                </div>
                                <div className="col">
                                    <label className="textocerrarlbl" ><AiOutlineClose style={{ cursor: "pointer" }}
                                        onClick={this.closemodal.bind(this, 0)} /></label>
                                </div>
                            </div>


                            <div className="user-profile" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>

                                <br />
                                <div className="profile-details" style={{ textAlign: 'center', marginTop: "10px", width: "94%" }}>


                                    <div style={{ position: "relative", width: "100%", marginBottom: "10px" }}>

                                        <Input className="inputprofilenew" placeholder="Titulo" type={"text"} style={{ paddingLeft: "5px", paddingBottom: "2px" }}
                                            onChange={this.escribirtxt("txttitulo")} value={this.state.txttitulo} />
                                    </div>
                                    <br />
                                    <div className="row" >

                                        <div className="col-6">
                                            <Button onClick={this.btntipo.bind(this, 1)} className="btnCuentasUser" style={{ background: this.state.idtipo == "expense" ? "#4137a4" : "#51a3ed", color: this.state.idtipo == "expense" ? "white" : "black" }}>
                                                Egreso
                                            </Button>
                                        </div>
                                        <div className="col-6">
                                            <Button onClick={this.btntipo.bind(this, 0)} className="btnCuentasUser" style={{ background: this.state.idtipo == "income" ? "#4137a4" : "#51a3ed", color: this.state.idtipo == "income" ? "white" : "black" }}>
                                                Ingreso
                                            </Button>
                                        </div>
                                    </div>
                                    <br />

                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <label className="txtlabel">Categoría:</label>
                                        </div>

                                        <div className="col align-items-center">
                                            <Button onClick={this.opencategori} className="btnElegir w-100"
                                                style={{
                                                    backgroundColor: `rgba(${parseInt(this.state.arrayCategoria.color.slice(0, 2), 16)}, ${parseInt(this.state.arrayCategoria.color.slice(2, 4), 16)}, ${parseInt(this.state.arrayCategoria.color.slice(4, 6), 16)}, 0.4)`,
                                                    color: "black",
                                                }}>
                                                {this.state.arrayCategoria.name}
                                            </Button>
                                        </div>
                                    </div>
                                    <br />


                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <label className="txtlabel">Fecha:</label>
                                        </div>
                                        <div className="col">
                                            <DatePicker fullWidth
                                                selected={this.state.fechaSeleccionada}
                                                onChange={this.manejarCambioFecha}
                                                dateFormat="yyyy-MM-dd"
                                                placeholderText="Elige una fecha"
                                                className="datepicker"

                                            />
                                        </div>
                                    </div>
                                    <br />

                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <label className="txtlabel">Cantidad:</label>
                                        </div>
                                        <div className="col" style={{ position: "relative" }}>
                                            <label className="lblflotoante">{this.state.idtipo == "expense" ? "-" : "+"}</label>
                                            <label className="lblflotoantesigno">{this.state.idtipo == "expense" ? "$" : "$"}</label>

                                            <input className="txtcanti" type="text" maxLength={"11"} placeholder="0.00"
                                                value={this.state.txtcantidad} onChange={this.manejarCambio} />
                                        </div>
                                    </div>

                                    <br />
                                    <div className="row align-items-center">
                                        <div className="col-auto">
                                            <label className="txtlabel">Recurrencia:</label>
                                        </div>

                                        <div className="col align-items-center">
                                            <Button onClick={this.openfrecuencia} className="btnElegir w-100"
                                                style={{
                                                    backgroundColor: "#51a3ed",
                                                    color: "black",
                                                }}>
                                                {this.state.tituloG}

                                            </Button>
                                        </div>
                                    </div>
                                    <br />

                                    <div className="row align-items-center">
                                        <div className="col">
                                            <label className="txtlabel">Descripción:</label>
                                        </div>
                                        <div className="col">
                                            <textarea className="inputprofilenew" type={"text"} style={{ paddingTop: "5px", marginLeft: "5px", paddingLeft: "5px", paddingBottom: "2px" }} rows={4} cols={40}
                                                onChange={this.escribirtxt("txtdescripcion")} value={this.state.txtdescripcion} />

                                        </div>
                                    </div>



                                    <Button onClick={this.guardatos} variant="contained" fullWidth
                                        style={{
                                            marginTop: "25px",
                                            textTransform: "capitalize",
                                            borderRadius: "30px",
                                            padding: "3px",
                                            fontSize: "medium",
                                            backgroundColor: "#4137a4",
                                            color: "white"
                                        }}>
                                        {this.props.bandera == "editar" ? "Actualizar" : "Agregar"}
                                    </Button>

                                </div>
                            </div>

                        </div>

                    </ModalBody>
                </Modal>
            </>
        );
    }
}