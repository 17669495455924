import React, { Component } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { Modal, ModalBody } from "reactstrap";
import "./ListaPlanes.css";

class ListaPlanes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "Plan Premium",
      description: "¡Lleva tu experiencia al siguiente nivel con nuestro plan Premium!",
      benefits: [
        "✔ Cuentas ilimitadas",
        "✔ Respaldo de tus cuentas",
        "✔ Soporte prioritario 24/7",
        "✔ Integraciones avanzadas",
        "✔ Sin anuncios",
      ],
      price: "9.99",
      moneda: "MXN",
      duration: "Por mes",
      isModalOpen: false,
      newBenefit: "",
    };
  }

  toggleModal = (ban) => {
    if (ban == 1) {
      this.setState({ isModalOpen: true });
    } else {
      this.setState({ isModalOpen: false });
    }
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleBenefitChange = (event) => {
    this.setState({ newBenefit: event.target.value });
  };

  addBenefit = () => {
    if (this.state.newBenefit.trim() !== "") {

      let nombre = ""
      var cadena = this.state.newBenefit.toLowerCase().split(' ');

      for (var i = 0; i < cadena.length; i++) {
        cadena[i] = cadena[i].charAt(0).toUpperCase() + cadena[i].substring(1);
      }
      nombre = cadena.join(' ');


      this.setState((prevState) => ({
        benefits: [...prevState.benefits, "✔ " + nombre],
        newBenefit: "",
      }));
    }
  };

  removeBenefit = (index) => {
    this.setState((prevState) => ({
      benefits: prevState.benefits.filter((_, i) => i !== index),
    }));
  };

  saveChanges = () => {
    this.toggleModal();
  }

  render() {
    return (
      <>
        <div className="contenedorhome">
          <br />
          <label className="lbllistat letra">Planes App</label>
          <div className="shr"></div>
          <br /> <br />

          <div className="premium-card">
            <h2 className="premium-title">{this.state.title}</h2>
            <p className="premium-description">{this.state.description}</p>

            <ul className="premium-benefits">
              {this.state.benefits.map((benefit, index) => (
                <li key={index}>{benefit}</li>
              ))}
            </ul>

            <div className="premium-footer">
              <p className="premium-price">
                ${this.state.price} {this.state.moneda}
              </p>
              <p className="premium-duration">{this.state.duration}</p>
              <button className="premium-btn" onClick={this.toggleModal.bind(this, 1)}>
                Modificar Plan
              </button>
            </div>
          </div>
        </div>


        <Modal isOpen={this.state.isModalOpen}
          size="md" aria-labelledby=""
          centered style={{ width: "400px" }}>

          <ModalBody>

            <div className="row" >
              <div className="col-auto ">
                <label className="lbltitlesModal" style={{ fontSize: "large", color: "#0061fe" }}>Editar Plan
                </label>
              </div>
              <div className="col">
                <label className="textocerrarlbl" ><AiOutlineClose style={{ cursor: "pointer" }}
                  onClick={this.toggleModal.bind(this, 0)} /></label>
              </div>
            </div>


            <div className="modal-content-wrapper">

              <label className="lbltitlemodal">
                Título:
                <input className="txttitless"
                  type="text"
                  name="title"
                  value={this.state.title}
                  onChange={this.handleInputChange}
                />
              </label>

              <label className="lbltitlemodal">
                Descripción:
                <textarea className="txttitless"
                  name="description"
                  value={this.state.description}
                  onChange={this.handleInputChange}
                />
              </label>

              <label className="lbltitlemodal">
                Beneficios:

                <ul style={{ marginTop: "10px" }}>
                  {this.state.benefits.map((benefit, index) => (
                    <li key={index} className="divcolbeni">
                      {benefit}{" "}
                      <button
                        className="remove-benefit-btn"
                        onClick={() => this.removeBenefit(index)}>
                        ❌
                      </button>
                    </li>
                  ))}
                </ul>
                <input className="txttitless"
                  type="text"
                  value={this.state.newBenefit}
                  onChange={this.handleBenefitChange}
                  placeholder="Agregar beneficio"
                />
                <button className="add-btn" onClick={this.addBenefit}>Agregar</button>
              </label>

              <label className="lbltitlemodal">
                Precio:
                <input className="txttitless"
                  type="text"
                  name="price"
                  value={this.state.price}
                  onChange={this.handleInputChange}
                />
              </label>

              <label className="lbltitlemodal">
                Moneda:
                <input className="txttitless"
                  type="text"
                  name="moneda"
                  value={this.state.moneda}
                  onChange={this.handleInputChange}
                />
              </label>

              <label className="lbltitlemodal">
                Duración:
                <input className="txttitless"
                  type="text"
                  name="duration"
                  value={this.state.duration}
                  onChange={this.handleInputChange}
                />
              </label>

              <div className="modal-actions">
                <button className="save-btn" onClick={this.saveChanges}>
                  Guardar Cambios
                </button>
                <button className="cancel-btn" onClick={this.toggleModal}>
                  Cancelar
                </button>
              </div>
            </div>

          </ModalBody>
        </Modal>


      </>
    );
  }
}

export default ListaPlanes;
