import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import SpinnerModal from "../../SpinnerModal/SpinnerModal";
import "./agregarCuenta.css"
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { Button } from "@mui/material";
import { GrFormSearch } from 'react-icons/gr';
import { Collapse } from "reactstrap";
import { IoTriangle } from "react-icons/io5";
import { getCategorias } from "../../../servicios/api";
import { AiOutlineClose } from "react-icons/ai";


export default class ModalCategory extends React.Component {

    constructor(props) {
        super(props)
        //console.log(this.props);
        this.state = {
            loanding: false,
            show: this.props.show,
            bandera: props.bandera,
            arrayCategoria: [],
            txt_buscador: "",
            array_filtro: [],
            icons: ["0.circle", "0.circle.fill", "0.square", "0.square.fill", "00.circle"],
            array: [],
            array_cat_padre: {},
            id_Categoria: "",
            selecCategoria: [],
            array_subCat: [],

        }
    }

    async componentDidMount() {


        this.setState({ loanding: true })
        var resp = await getCategorias()
        var da = this.state.txt_buscador;
        this.setState({ txt_buscador: "" })

        if (resp.code == 0) {
            let ban = 0;
            let pos = 0;
            let arraytem = resp.data;

            arraytem.sort((a, b) => {
                const nameA = a.name.toLowerCase();
                const nameB = b.name.toLowerCase();
                if (nameA < nameB) {
                    return -1;
                }
                if (nameA > nameB) {
                    return 1;
                }
                return 0;
            });


            //console.log("buscando cat id " + this.state.id_Categoria)
            for (let i = 0; i < arraytem.length; i++) {
                arraytem[i].collapse = false
                if (arraytem[i].idcategoria == this.state.id_Categoria) {

                    ban++;
                    pos = i;

                    //arraytem[i].collapse = false

                }
            }




            await this.setState({ array: arraytem })
            if (ban <= 0) {
                //console.log("NO")
                this.setState({ loanding: false })
            }
            else {

                //console.log("Noo")
                setTimeout(async () => {

                    this.setState({ txt_buscador: da })
                    //console.log("es busqueda")
                    this.individual(da)
                    //this.abrirCollage(arraytem[pos], pos);
                    //arraytem[pos].collapse = true
                    this.setState({ loanding: false })

                }, 1000);

            }

        }
        else {
            this.setState({ loanding: false })
        }


    }



    individual = async (e) => {

        var cadenain = e.trim()
        this.setState({ txt_buscador: cadenain });
        var filtropuesto = this.state.array;
        var filtrofinal = [];


        if (cadenain.toLocaleLowerCase().length >= 1) {
            filtrofinal = filtropuesto.filter((item) => item.name.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase())
                || item.description.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase())
            )

            if (filtrofinal.length <= 0) {
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                this.setState({ array_filtro: filtrofinal })
            }
        }


    }

    save_input_locacion = async (e) => {

        var cadenain = e.target.value.trim()
        this.setState({ txt_buscador: cadenain });
        var filtropuesto = this.state.array;
        var filtrofinal = [];


        if (cadenain.toLocaleLowerCase().length >= 1) {
            filtrofinal = filtropuesto.filter((item) => item.name.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase())
                || item.description.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase())
            )

            if (filtrofinal.length <= 0) {
                this.setState({ array_filtro: [] })
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                this.setState({ array_filtro: filtrofinal })
            }
        }


    }

    filtrarlista = async (ban) => {

        var cadenain = ban == 1 ? "expense" : ban == 2 ? "income" : ""
        // this.setState({ txt_buscador: cadenain });
        var filtropuesto = this.state.array;
        var filtrofinal = [];

        if (cadenain.toLocaleLowerCase().length >= 1) {
            filtrofinal = filtropuesto.filter((item) => item.name.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase())
                || item.description.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase())
                || item.type.toLocaleLowerCase().includes(cadenain.toLocaleLowerCase()),
            )

            if (filtrofinal.length <= 0) {
                toast.info("No hay resultados", { autoClose: 1000, position: toast.POSITION.TOP_LEFT })
            }
            else {
                this.setState({ array_filtro: filtrofinal })
            }
        }


    }

    buscarCategoriaPadre = (parentId) => {
        const parentCategory = this.state.array.find(cat => cat.idcategoria === parentId);
        return parentCategory ? parentCategory.name : '';
    };


    seleccionarTabla = (idcategoria, bandera) => {
        //console.log(bandera + "  " + idcategoria)
        if (bandera === "lista") {
            const { array } = this.state;
            const newData = array.map((item) => {
                if (item.idcategoria == idcategoria) {
                    return { ...item, seleccionar: true };
                } else {
                    return { ...item, seleccionar: false };
                }
            });
            this.setState({ array: newData });
        }

        else if (bandera === "subcat") {
            const { array_subCat } = this.state;
            const newData = array_subCat.map((item) => {
                if (item.idcategoria == idcategoria) {
                    return { ...item, seleccionar: true };
                } else {
                    return { ...item, seleccionar: false };
                }
            });
            this.setState({ array_subCat: newData });
        }
        else { // filtro
            const { array_filtro } = this.state;
            const newData = array_filtro.map((item) => {
                if (item.idcategoria == idcategoria) {
                    return { ...item, seleccionar: true };
                } else {
                    return { ...item, seleccionar: false };
                }
            });
            this.setState({ array_filtro: newData });

        }
    }


    abrirCollage = async (array, pos) => {

        this.setState({ array_cat_padre: array });
        var filtropuesto = this.state.array;
        var resp = filtropuesto[pos].collapse;
        for (let i = 0; i < filtropuesto.length; i++) {
            if (pos == i) {
                if (resp == true) {
                    filtropuesto[i].collapse = false

                    this.setState({ array_subCat: [] })

                }
                else {

                    const datosFiltrados = filtropuesto.filter(item => item.parent === array.idcategoria);
                    datosFiltrados.sort((a, b) => {
                        const nameA = a.name.toLowerCase();
                        const nameB = b.name.toLowerCase();
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    });
                    this.setState({ array_subCat: datosFiltrados });
                    filtropuesto[i].collapse = true
                }
            }
            else {
                filtropuesto[i].collapse = false
            }
        }
        this.setState({ array: filtropuesto })

    }

    closemodal = (dato, item) => {
        this.props.cerrarmodal(dato, item);
        this.setState({ show: false });
    }


    elegirCats = (item) => {

        this.seleccionarTabla(item.idcategoria, "subcat")
        const parentValue = item.parent;
        const array_cat_padre = this.state.array.find(
            (element) => element.parent === parentValue
        );
        var json = {
            cat: array_cat_padre,
            subCat: item,
        };
        console.log(json);
        this.closemodal(1, json);
    }


    render() {

        let count = 0;

        return (

            <>

                <ToastContainer />

                {(this.state.loanding == true) ? (
                    <SpinnerModal tipo="full" show={this.state.loanding} />
                ) : null}


                <Modal isOpen={this.state.show}
                    size="lg"
                    aria-labelledby=""
                    centered


                    toggle={this.closemodal.bind(this, 0)} >

                    <ModalBody className="backgrounddiv" >



                        <div className="contenedorhome  ">

                            <div className="row scroll">
                                <div className="col">
                                    <br />
                                    <div className="row" >
                                        <div className="col-auto ">
                                            <label style={{ fontSize: "25px", color: "black", fontWeight: "bold" }}>Selecciona una categoría
                                            </label>
                                        </div>
                                        <div className="col">
                                            <label className="textocerrarlbl" ><AiOutlineClose style={{ cursor: "pointer" }}
                                                onClick={this.closemodal.bind(this, 0)} /></label>
                                        </div>
                                    </div>

                                    <br />
                                    <div className="row align-items-end">
                                        <div className="col-auto ">
                                            <div className="input-group ">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="basic-addon1"> <GrFormSearch /></span>
                                                </div>
                                                <input type="search" onChange={this.save_input_locacion} value={this.state.txt_buscador}
                                                    className="form-control" aria-label="Username" placeholder="Buscar" aria-describedby="basic-addon1"></input>
                                            </div>
                                        </div>
                                        <div className="col"></div>




                                    </div>


                                    <div className="shr "></div>
                                    <br />




                                    <div className="divhorizontal ">



                                        <div className="row srows">

                                            <div className="col-1 textocentro sinpadding">

                                            </div>
                                            <div className="col-5 sinpadding" onClick={() => this.ordenarLista('name')}>
                                                <label className="txttitlestable">Nombre</label>
                                            </div>


                                            <div className="col-2 textocentro sinpadding" onClick={() => this.ordenarLista('type')}>
                                                <label className="txttitlestable">Tipo</label>
                                            </div>

                                            {this.state.txt_buscador.length > 0 ?

                                                <div className="col-2 textocentro sinpadding" onClick={() => this.ordenarLista('type')}>
                                                    <label className="txttitlestable">Padre</label>
                                                </div>
                                                : null}
                                            <div className="col textocentro sinpadding">



                                            </div>



                                        </div>
                                        <div className='divscrollhome'>

                                            {this.state.txt_buscador.length == 0 ?
                                                <>
                                                    {this.state.array != [] ?
                                                        <>
                                                            {this.state.array.map((item, index) => (

                                                                <div key={index}>

                                                                    {item.parent == "" ? <>
                                                                        <p style={{ display: 'none' }}>{count++}</p>
                                                                        <div key={index} className={`row ${item.collapse ? 'nuevocolorfondo' : count % 2 === 0 ? 'eve' : 'low'}`}>

                                                                            <div className="col-1 spaciostabla textocentro sinpadding mostrar_cursor ">

                                                                                <div onClick={this.abrirCollage.bind(this, item, index)}>

                                                                                    <label className="subtexttableRecordatorio">{count}</label>
                                                                                    {item.collapse == false ?
                                                                                        <IoIosArrowDown style={{ marginLeft: "5px", cursor: "pointer" }} />
                                                                                        :
                                                                                        <IoIosArrowUp style={{ marginLeft: "5px", cursor: "pointer" }} />
                                                                                    }
                                                                                </div>

                                                                            </div>

                                                                            <div className="col-5 spaciostabla  sinpadding mostrar_cursor" onClick={this.abrirCollage.bind(this, item, index)}>
                                                                                <label className="subtexttableRecordatorio">{item.name}</label>
                                                                            </div>

                                                                            <div className="col-2 spaciostabla textocentro sinpadding mostrar_cursor" onClick={this.abrirCollage.bind(this, item, index)}>
                                                                                <label className="subtexttableRecordatorio" style={{ fontWeight: item.type == "income" ? "bold" : "normal" }}>{item.type == "income" ? 'Ingresos' : "Egresos"}</label>
                                                                            </div>

                                                                            <div className="col textocentro sinpadding" onClick={this.abrirCollage.bind(this, item, index)}>

                                                                            </div>

                                                                        </div>


                                                                        <Collapse isOpen={item.collapse} >

                                                                            <div className=" bordertable">
                                                                                <br />
                                                                                <div className="row  align-items-center">
                                                                                    <div className="col-auto">
                                                                                        <div style={{ width: "15px" }}> </div> </div>
                                                                                    <div className="col">

                                                                                        <div className="row">
                                                                                            <div className="col">
                                                                                                <label className="txttitlestable"><b>Tienes: {this.state.array_subCat.length} Sub Categorías</b></label>
                                                                                            </div>
                                                                                        </div>

                                                                                        <div className="row SubCatFondo">

                                                                                            <div className="col-1 textocentro sinpadding">

                                                                                            </div>
                                                                                            <div className="col-7 sinpadding">
                                                                                                <label className="txttitlestable">Nombre</label>
                                                                                            </div>

                                                                                            <div className="col-2 textocentro sinpadding">
                                                                                                <label className="txttitlestable">Tipo</label>
                                                                                            </div>

                                                                                            <div className="col-2 textocentro sinpadding">
                                                                                            </div>

                                                                                        </div>

                                                                                        <>
                                                                                            {this.state.array_subCat != [] ?
                                                                                                <>
                                                                                                    {this.state.array_subCat.map((item, subindex) => (



                                                                                                        <div key={subindex} className={`row ${item.seleccionar ? 'nuevocolorfondo' : subindex % 2 === 0 ? 'fongris' : 'fonblan'}`}>

                                                                                                            {/*<div key={subindex} className={subindex % 2 === 0 ? "row fongris" : "row fonblan"}>*/}


                                                                                                            <div className="col-1 spaciostabla textocentro sinpadding mostrar_cursor" onClick={this.seleccionarTabla.bind(this, item.idcategoria, "subcat")} >
                                                                                                                <label className="subtexttableRecordatorio">{(subindex + 1)}</label>

                                                                                                            </div>
                                                                                                            <div className="col-7 spaciostabla  sinpadding mostrar_cursor" onClick={this.seleccionarTabla.bind(this, item.idcategoria, "subcat")} >
                                                                                                                <label className="subtexttableRecordatorio"><b>{item.name}</b></label><br />
                                                                                                                <label className="subtexttableRecordatorio" style={{ paddingLeft: "13px" }}>{item.description}</label>
                                                                                                            </div>


                                                                                                            <div className="col-2 spaciostabla textocentro sinpadding mostrar_cursor" onClick={this.seleccionarTabla.bind(this, item.idcategoria, "subcat")} >
                                                                                                                <label className="subtexttableRecordatorio">{item.type}</label>
                                                                                                            </div>



                                                                                                            <div className="col-1 spaciostabla textocentro sinpadding ">
                                                                                                                <Button className="btnCuentasUser" style={{ background: "#51a3ed", color: "black", height: "25px" }}
                                                                                                                    onClick={this.elegirCats.bind(this, item)}>
                                                                                                                    Elegir
                                                                                                                </Button>

                                                                                                            </div>
                                                                                                        </div>

                                                                                                    ))}


                                                                                                </>
                                                                                                : <></>
                                                                                            }
                                                                                        </>

                                                                                    </div>

                                                                                    <div className="col-auto">
                                                                                        <div style={{ width: "15px" }}> </div> </div>
                                                                                </div>

                                                                                <br />
                                                                            </div>

                                                                        </Collapse>

                                                                    </>
                                                                        :
                                                                        null
                                                                    }

                                                                </div>

                                                            ))}
                                                        </>
                                                        :
                                                        <>
                                                            <label style={{ width: "100%", textAlign: "center", fontSize: "large", color: "black" }}>Sin Categorias</label>
                                                        </>
                                                    }
                                                </>
                                                :
                                                <>
                                                    {this.state.array_filtro != [] ?
                                                        <>
                                                            {this.state.array_filtro.map((item, indexx) => (

                                                                <div key={indexx}>


                                                                    <div className={`row ${item.seleccionar ? 'nuevocolorfondo' : indexx % 2 === 0 ? 'eve' : 'low'}`}
                                                                        onClick={this.seleccionarTabla.bind(this, item.idcategoria, "filtro")}>
                                                                        <div className="col-1 spaciostabla textocentro sinpadding ">

                                                                            {item.parent == "" ?
                                                                                <IoTriangle style={{ color: "black", marginLeft: "5px" }} />
                                                                                :
                                                                                null
                                                                            }
                                                                            <label className="subtexttableRecordatorio" >{(indexx + 1)}</label>

                                                                        </div>

                                                                        <div className="col-5 spaciostabla  sinpadding ">
                                                                            <label className="subtexttableRecordatorio">{item.name}</label>
                                                                        </div>

                                                                        <div className="col-2 spaciostabla textocentro sinpadding">
                                                                            <label className="subtexttableRecordatorio">{item.type}</label>

                                                                        </div>

                                                                        <div className="col-2 spaciostabla textocentro sinpadding mostrar_cursor">
                                                                            <label className="subtexttableRecordatorio" style={{ color: item.seleccionar ? "white" : "black" }}>{item.parent == "" ? '' : this.buscarCategoriaPadre(item.parent)}</label>
                                                                        </div>

                                                                        <div className="col spaciostabla textocentro sinpadding">
                                                                            <Button className="btnCuentasUser" style={{ background: "#51a3ed", color: "black", height: "25px" }}
                                                                                onClick={this.elegirCats.bind(this, item)}>
                                                                                Elegir
                                                                            </Button>

                                                                        </div>

                                                                    </div>

                                                                </div>

                                                            ))}
                                                        </>
                                                        : <></>
                                                    }
                                                </>}
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>

                    </ModalBody>

                </Modal>
            </>
        )
    }
}

