import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { ToastContainer, toast } from 'react-toastify';
import { Button } from "@mui/material";
import SpinnerModal from "../../SpinnerModal/SpinnerModal";
import "./agregarCuenta.css"
import { AiOutlineClose } from "react-icons/ai";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";


export default class ModalFracuencia extends React.Component {

    constructor(props) {
        super(props)


        this.state = {
            loanding: false,
            show: this.props.show,
            datosfrecuancia: props.infoModal,
            bandera: props.bandera,
            fechaSeleccionada: new Date(),
            selectTipo: "unique",
            selectDuracion: "Repetirnveses",
            txtcantidad: 1,
            txtdia: 15,
            txtsemana: 1,
            txtmes: 1,
            txtanio: 1,


            txtnumVeces: 1,
            txthasta: new Date(),

        }
    }

    async componentDidMount() {

        var datos = JSON.parse(this.props.datos);
        if (this.props.tipoduracion === "Hasta") {
            this.setState({
                selectTipo: datos.tipo, selectDuracion: datos.tipoduracion,
                txtcantidad: datos.cantidaD, txtnumVeces: datos.nveces, txthasta: new Date(datos.hasta),
            });
        }
        else {
            this.setState({
                selectTipo: datos.tipo, selectDuracion: datos.tipoduracion,
                txtcantidad: datos.cantidaD, txtnumVeces: datos.nveces,
            });
        }



    }


    manejarCambioFecha = (fecha) => {
        this.setState({ txthasta: fecha });
    }

    closemodal = (dato, item) => {
        this.props.cerrarmodal(dato, item);
        this.setState({ show: false });
    }

    guardatos = async () => {

        // if (this.state.selectTipo == "unique")
        // else if (this.state.selectTipo == "daily") 
        // else if (this.state.selectTipo == "weekly") 
        // else if (this.state.selectTipo == "monthly") 
        // else if (this.state.selectTipo == "yearly") 

        if (this.state.selectDuracion === "Repetirnveses" && this.state.selectTipo !== "unique" && this.state.selectTipo !== "unica") {
            if (this.state.txtnumVeces <= 0) {
                toast.warn("Duración debe ser mayor a cero", { autoClose: 4000, position: toast.POSITION.TOP_LEFT });
                return;
            }
        }
        if (this.state.txtcantidad <= 0 && this.state.selectTipo != "unique" && this.state.selectTipo != "unica") {
            toast.warn("La cantidad de repeticiones debe ser mayor a cero", { autoClose: 4000, position: toast.POSITION.TOP_LEFT });
            return;
        }
        var datos = {
            "tipo": this.state.selectTipo,
            "cantidaD": this.state.selectTipo === "daily" ? 1 : this.state.txtcantidad,
            "tipoduracion": this.state.selectDuracion,
            "hasta": this.state.selectDuracion === "Hasta" ? this.state.txthasta : "",
            "nveces": this.state.selectDuracion === "daily" ? 0 : this.state.txtnumVeces,
        }
        this.closemodal(1, datos);
    }

    escribirtxt = (prop) => (event) => {
        this.setState({ [prop]: event.target.value });
    }

    render() {
        return (
            <>
                <ToastContainer />

                {this.state.loanding && <SpinnerModal tipo="full" show={this.state.loanding} />}

                <Modal
                    isOpen={this.state.show}
                    size="md"
                    aria-labelledby=""
                    centered
                    toggle={this.closemodal.bind(this, 0)}>

                    <ModalBody className="backgrounddiv">
                        <div className="divperfilediv">
                            <div className="row">
                                <div className="col-auto">
                                    <label className="lbltitlesModal" style={{ fontSize: "large" }}>
                                        Frecuencia
                                    </label>
                                </div>
                                <div className="col">
                                    <label className="textocerrarlbl">
                                        <AiOutlineClose
                                            style={{ cursor: "pointer" }}
                                            onClick={this.closemodal.bind(this, 0)}
                                        />
                                    </label>
                                </div>
                            </div>

                            <div className="user-profile"
                                style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                <div className="profile-details"
                                    style={{ textAlign: 'center', marginTop: "10px", width: "94%" }}>

                                    <label className="lbltduracion">Este evento se repetirá</label>

                                    {/* Radios para el tipo de repetición */}
                                    <div className="divdiracionTipo">
                                        <div className="form-check">
                                            <label className="lbltfree">
                                                <input
                                                    type="radio"
                                                    name="react-tips"
                                                    value="unique"
                                                    checked={this.state.selectTipo === "unique" || this.state.selectTipo === "unica"}
                                                    className="form-check-input"
                                                    onChange={this.escribirtxt("selectTipo")}
                                                />
                                                Única ocasión
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <label className="lbltfree">
                                                <input
                                                    type="radio"
                                                    name="react-tips"
                                                    value="daily"
                                                    checked={this.state.selectTipo === "daily"}
                                                    className="form-check-input"
                                                    onChange={this.escribirtxt("selectTipo")}
                                                />
                                                Diario
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <label className="lbltfree">
                                                <input
                                                    type="radio"
                                                    name="react-tips"
                                                    value="weekly"
                                                    checked={this.state.selectTipo === "weekly"}
                                                    className="form-check-input"
                                                    onChange={this.escribirtxt("selectTipo")}
                                                />
                                                Cada
                                                <input
                                                    type="text"
                                                    placeholder="0"
                                                    maxLength={"2"}
                                                    className="txtcantidaFrecuancia"
                                                    value={this.state.txtcantidad}
                                                    onChange={this.escribirtxt("txtcantidad")}
                                                    style={{
                                                        display: this.state.selectTipo === "weekly" ? "inline-block" : "none",
                                                    }}
                                                />
                                                {" semana"}
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <label className="lbltfree">
                                                <input
                                                    type="radio"
                                                    name="react-tips"
                                                    value="monthly"
                                                    checked={this.state.selectTipo === "monthly"}
                                                    className="form-check-input"
                                                    onChange={this.escribirtxt("selectTipo")}
                                                />
                                                Cada
                                                <input
                                                    type="text"
                                                    placeholder="1"
                                                    maxLength={"2"}
                                                    className="txtcantidaFrecuancia"
                                                    value={this.state.txtcantidad}
                                                    onChange={this.escribirtxt("txtcantidad")}
                                                    style={{
                                                        display: this.state.selectTipo === "monthly" ? "inline-block" : "none",
                                                    }}
                                                />
                                                {" mes"}
                                            </label>
                                        </div>

                                        <div className="form-check">
                                            <label className="lbltfree">
                                                <input
                                                    type="radio"
                                                    name="react-tips"
                                                    value="yearly"
                                                    checked={this.state.selectTipo === "yearly"}
                                                    className="form-check-input"
                                                    onChange={this.escribirtxt("selectTipo")}
                                                />
                                                Cada
                                                <input
                                                    type="text"
                                                    placeholder="1"
                                                    maxLength={"2"}
                                                    className="txtcantidaFrecuancia"
                                                    value={this.state.txtcantidad}
                                                    onChange={this.escribirtxt("txtcantidad")}
                                                    style={{
                                                        display: this.state.selectTipo === "yearly" ? "inline-block" : "none",
                                                    }}
                                                />
                                                {" año"}
                                            </label>
                                        </div>
                                    </div>
                                    <br />

                                    {this.state.selectTipo !== "unique" && this.state.selectTipo !== "unica" && (
                                        <div className="divdiracion">
                                            <label className="lbltduracion">Duración</label>

                                            {/*        <div className="form-check">
                                                <label className="lbltfree">
                                                    <input
                                                        type="radio"
                                                        name="react-tips-duracion"
                                                        value="siempre"
                                                        checked={this.state.selectDuracion === "siempre"}
                                                        className="form-check-input"
                                                        onChange={this.escribirtxt("selectDuracion")}
                                                    />
                                                    Siempre
                                                </label>
                                            </div>
                                    */}

                                            <div className="form-check">
                                                <label className="lbltfree">
                                                    <input
                                                        type="radio"
                                                        name="react-tips-duracion"
                                                        value="Repetirnveses"
                                                        checked={this.state.selectDuracion === "Repetirnveses"}
                                                        className="form-check-input"
                                                        onChange={this.escribirtxt("selectDuracion")}
                                                    />
                                                    <input
                                                        type="text"
                                                        placeholder="Núm"
                                                        maxLength={"2"}
                                                        className="txtcantidaFrecuancia"
                                                        value={this.state.txtnumVeces}
                                                        onChange={this.escribirtxt("txtnumVeces")}
                                                        style={{
                                                            display: this.state.selectDuracion === "Repetirnveses" ? "inline-block" : "none",
                                                        }}
                                                    />
                                                    Número de veces
                                                </label>
                                            </div>

                                            <div className="form-check">
                                                <label className="lbltfree">
                                                    <input
                                                        type="radio"
                                                        name="react-tips-duracion"
                                                        value="Hasta"
                                                        checked={this.state.selectDuracion === "Hasta"}
                                                        className="form-check-input"
                                                        onChange={this.escribirtxt("selectDuracion")}
                                                    />
                                                    Hasta{" "}
                                                    {this.state.selectDuracion === "Hasta" && (
                                                        <>
                                                            -{" "}
                                                            <b>
                                                                {this.state.txthasta.toLocaleDateString("es-ES", {
                                                                    day: "2-digit",
                                                                    month: "2-digit",
                                                                    year: "numeric",
                                                                })}
                                                            </b>
                                                        </>
                                                    )}
                                                </label>

                                                {this.state.selectDuracion === "Hasta" && (
                                                    <div style={{ marginTop: "10px" }}>
                                                        <DatePicker
                                                            selected={this.state.txthasta}
                                                            onChange={this.manejarCambioFecha}
                                                            dateFormat="yyyy-MM-dd"
                                                            className="datepicker"
                                                            /*minDate={new Date()}*/
                                                            inline
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    <Button
                                        onClick={this.guardatos}
                                        variant="contained"
                                        fullWidth
                                        style={{
                                            marginTop: "25px",
                                            textTransform: "capitalize",
                                            borderRadius: "30px",
                                            padding: "3px",
                                            fontSize: "medium",
                                            backgroundColor: "#4137a4",
                                            color: "white",
                                        }}
                                    >
                                        {this.props.bandera === "editar" ? "Actualizar" : "Agregar"}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                </Modal>
            </>
        );
    }
}    